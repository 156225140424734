/* eslint-disable max-len */
import React from 'react';

const Svg13 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="132"
      height="34"
      viewBox="0 0 132 34"
      fill="currentColor"
    >
      <path
        d="M7.26964 13.9564H2.66941C2.00471 13.9564 1.67236 13.7678 1.67236 13.3905V0.808481C1.67236 0.43119 2.00471 0.242544 2.66941 0.242544H7.26964C9.25715 0.242544 10.8037 0.916278 11.9094 2.26375C13.015 3.61121 13.5678 5.23828 13.5678 7.14495C13.5678 9.05161 13.015 10.6652 11.9094 11.9857C10.8037 13.2995 9.25715 13.9564 7.26964 13.9564ZM7.26964 12.8245C8.75041 12.8245 9.8363 12.198 10.5273 10.9448C11.2249 9.68492 11.5737 8.41494 11.5737 7.13484C11.5737 5.86822 11.2249 4.5915 10.5273 3.30466C9.82972 2.01783 8.74382 1.37442 7.26964 1.37442H3.67633V12.8245H7.26964Z"
        fill="currentColor"
      />
      <path
        d="M24.9993 13.9564H16.1345C15.4171 13.9564 15.0585 13.7543 15.0585 13.35V0.848905C15.0585 0.444664 15.4171 0.242544 16.1345 0.242544H24.9993C25.6706 0.242544 26.0062 0.43119 26.0062 0.808481C26.0062 1.18577 25.6706 1.37442 24.9993 1.37442H17.0624V6.52848H23.7456C24.4103 6.52848 24.7426 6.71713 24.7426 7.09442C24.7426 7.47171 24.4103 7.66035 23.7456 7.66035H17.0624V12.8245H24.9993C25.6706 12.8245 26.0062 13.0132 26.0062 13.3905C26.0062 13.7678 25.6706 13.9564 24.9993 13.9564Z"
        fill="currentColor"
      />
      <path
        d="M37.6746 13.9564H28.8098C28.0924 13.9564 27.7338 13.7543 27.7338 13.35V0.848905C27.7338 0.444664 28.0924 0.242544 28.8098 0.242544H37.6746C38.3459 0.242544 38.6815 0.43119 38.6815 0.808481C38.6815 1.18577 38.3459 1.37442 37.6746 1.37442H29.7377V6.52848H36.4209C37.0856 6.52848 37.418 6.71713 37.418 7.09442C37.418 7.47171 37.0856 7.66035 36.4209 7.66035H29.7377V12.8245H37.6746C38.3459 12.8245 38.6815 13.0132 38.6815 13.3905C38.6815 13.7678 38.3459 13.9564 37.6746 13.9564Z"
        fill="currentColor"
      />
      <path
        d="M41.416 14.1989C40.7447 14.1989 40.4091 14.0103 40.4091 13.633V0.808481C40.4091 0.43119 40.7447 0.242544 41.416 0.242544H47.6253C48.6915 0.242544 49.6655 0.579411 50.5474 1.25315C51.4358 1.92688 51.8801 2.83979 51.8801 3.99187C51.8801 5.14396 51.4358 6.06024 50.5474 6.74071C49.6655 7.41444 48.6915 7.75131 47.6253 7.75131H42.4131V13.633C42.4131 14.0103 42.0807 14.1989 41.416 14.1989ZM47.6253 6.61944C48.2769 6.61944 48.8132 6.33647 49.2344 5.77053C49.6622 5.19786 49.8761 4.60497 49.8761 3.99187C49.8761 3.37878 49.6622 2.78926 49.2344 2.22332C48.8132 1.65739 48.2769 1.37442 47.6253 1.37442H42.4131V6.61944H47.6253Z"
        fill="currentColor"
      />
      <path
        d="M59.7084 14.1989C57.6419 14.1989 55.9834 13.5286 54.733 12.1878C53.4892 10.8404 52.8672 9.14257 52.8672 7.09442C52.8672 5.03953 53.4892 3.34509 54.733 2.0111C55.9834 0.670365 57.6419 0 59.7084 0C61.7748 0 63.43 0.670365 64.6739 2.0111C65.9243 3.34509 66.5495 5.03953 66.5495 7.09442C66.5495 9.14257 65.9243 10.8404 64.6739 12.1878C63.43 13.5286 61.7748 14.1989 59.7084 14.1989ZM59.7084 13.0671C61.3076 13.0671 62.5119 12.4338 63.3214 11.1671C64.1375 9.90052 64.5455 8.54294 64.5455 7.09442C64.5455 5.64589 64.1375 4.29169 63.3214 3.0318C62.5119 1.76518 61.3076 1.13187 59.7084 1.13187C58.1091 1.13187 56.9015 1.76518 56.0854 3.0318C55.2759 4.29169 54.8712 5.64589 54.8712 7.09442C54.8712 8.54294 55.2759 9.90052 56.0854 11.1671C56.9015 12.4338 58.1091 13.0671 59.7084 13.0671Z"
        fill="currentColor"
      />
      <path
        d="M79.2051 14.1989C78.9484 14.1989 78.7246 14.1484 78.5338 14.0474C77.7835 13.6633 77.2175 13.1479 76.8358 12.5011C76.4541 11.8543 76.1119 11.1907 75.8092 10.5102C75.5854 9.99821 75.2893 9.41206 74.9207 8.7518C74.5588 8.08481 74.0454 7.75131 73.3807 7.75131H70.0342V13.633C70.0342 14.0103 69.6986 14.1989 69.0273 14.1989C68.3626 14.1989 68.0302 14.0103 68.0302 13.633V0.808481C68.0302 0.43119 68.3626 0.242544 69.0273 0.242544H75.1379C76.2106 0.242544 77.2044 0.576042 78.1192 1.24304C79.0339 1.91004 79.4913 2.82631 79.4913 3.99187C79.4913 5.97939 78.3857 7.19548 76.1744 7.64014C76.543 7.99048 76.8457 8.39809 77.0826 8.86297C77.3261 9.32111 77.5499 9.77925 77.7539 10.2374C77.9842 10.7696 78.2607 11.3288 78.5831 11.915C78.9056 12.5011 79.3367 12.9323 79.8763 13.2086C80.0935 13.3231 80.2021 13.4646 80.2021 13.633C80.2021 13.7745 80.1165 13.8991 79.9454 14.0069C79.7546 14.1349 79.5078 14.1989 79.2051 14.1989ZM75.1379 6.61944C75.8026 6.61944 76.3587 6.33984 76.8062 5.78064C77.2603 5.22144 77.4874 4.62518 77.4874 3.99187C77.4874 3.35183 77.2603 2.75557 76.8062 2.20311C76.3587 1.65065 75.8026 1.37442 75.1379 1.37442H70.0342V6.61944H75.1379Z"
        fill="currentColor"
      />
      <path
        d="M82.2061 14.1989C81.5348 14.1989 81.1992 14.0103 81.1992 13.633V0.565937C81.1992 0.188646 81.5348 0 82.2061 0C82.8708 0 83.2031 0.188646 83.2031 0.565937V13.633C83.2031 14.0103 82.8708 14.1989 82.2061 14.1989Z"
        fill="currentColor"
      />
      <path
        d="M91.0413 14.1989C88.9748 14.1989 87.3163 13.5286 86.0659 12.1878C84.8221 10.8404 84.2002 9.14257 84.2002 7.09442C84.2002 5.03953 84.8221 3.34509 86.0659 2.0111C87.3163 0.670365 88.9748 0 91.0413 0C93.1078 0 94.7629 0.670365 96.0068 2.0111C97.2572 3.34509 97.8824 5.03953 97.8824 7.09442C97.8824 9.14257 97.2572 10.8404 96.0068 12.1878C94.7629 13.5286 93.1078 14.1989 91.0413 14.1989ZM91.0413 13.0671C92.6405 13.0671 93.8449 12.4338 94.6543 11.1671C95.4704 9.90052 95.8784 8.54294 95.8784 7.09442C95.8784 5.64589 95.4704 4.29169 94.6543 3.0318C93.8449 1.76518 92.6405 1.13187 91.0413 1.13187C89.4421 1.13187 88.2344 1.76518 87.4184 3.0318C86.6089 4.29169 86.2041 5.64589 86.2041 7.09442C86.2041 8.54294 86.6089 9.90052 87.4184 11.1671C88.2344 12.4338 89.4421 13.0671 91.0413 13.0671Z"
        fill="currentColor"
      />
      <path
        d="M110.005 14.1989C109.465 14.1989 109.1 14.0709 108.909 13.8149L100.923 2.81958V13.7745C100.923 14.0575 100.663 14.1989 100.143 14.1989C99.6231 14.1989 99.3632 14.0575 99.3632 13.7745V0.666997C99.3632 0.222332 99.758 0 100.548 0C101.081 0 101.443 0.128009 101.634 0.384028L109.63 11.3794V0.434558C109.63 0.144853 109.89 0 110.41 0C110.93 0 111.19 0.144853 111.19 0.434558V13.5522C111.19 13.9833 110.795 14.1989 110.005 14.1989Z"
        fill="currentColor"
      />
      <path
        d="M5.9276 33.6931C5.26491 33.6933 4.93353 33.5071 4.93347 33.1346L4.93141 21.0329L0.984501 21.0336C0.328308 21.0337 0.000179162 20.8475 0.000115661 20.475C5.2161e-05 20.1026 0.328117 19.9163 0.984311 19.9162L10.8565 19.9144C11.5126 19.9143 11.8408 20.1005 11.8408 20.4729C11.8409 20.8454 11.5128 21.0317 10.8566 21.0318L6.90973 21.0325L6.9118 33.1343C6.91186 33.5067 6.5838 33.693 5.9276 33.6931Z"
        fill="currentColor"
      />
      <path
        d="M22.8847 33.5704L14.1333 33.5719C13.4251 33.5721 13.071 33.3726 13.0709 32.9735L13.0688 20.6323C13.0687 20.2333 13.4228 20.0337 14.1309 20.0335L22.8824 20.032C23.545 20.0319 23.8764 20.218 23.8765 20.5905C23.8766 20.963 23.5452 21.1493 22.8825 21.1494L15.0472 21.1508L15.0481 26.2389L21.6457 26.2377C22.3019 26.2376 22.6301 26.4238 22.6301 26.7962C22.6302 27.1687 22.3021 27.355 21.6459 27.3551L15.0483 27.3563L15.0491 32.4544L22.8845 32.453C23.5472 32.4529 23.8785 32.639 23.8786 33.0115C23.8787 33.384 23.5474 33.5703 22.8847 33.5704Z"
        fill="currentColor"
      />
      <path
        d="M37.5996 29.4474C37.5996 29.5072 37.5801 29.617 37.5412 29.7766C37.1841 31.1136 36.4241 32.1213 35.2613 32.8C34.1049 33.4719 32.8868 33.808 31.6069 33.8083C29.5538 33.8086 27.9522 33.1638 26.802 31.8736C25.6584 30.5769 25.0863 28.9009 25.086 26.8457C25.0856 24.7572 25.6733 23.0611 26.849 21.7572C28.0313 20.4468 29.6619 19.7913 31.7409 19.791C33.0143 19.7907 34.2358 20.1397 35.4054 20.8379C36.5815 21.5294 37.2898 22.5502 37.5304 23.9003C37.5564 24.0067 37.5694 24.0899 37.5694 24.1497C37.5695 24.5222 37.2512 24.7085 36.6145 24.7086C35.9843 24.7087 35.6464 24.5359 35.6009 24.19C35.3146 22.0018 34.028 20.9079 31.7411 20.9084C30.1559 20.9086 28.9768 21.5341 28.2038 22.7846C27.4374 24.0285 27.0543 25.3754 27.0546 26.8254C27.0548 28.2487 27.4221 29.5855 28.1565 30.8358C28.8909 32.0795 30.0474 32.7011 31.6262 32.7008C33.9196 32.7004 35.2513 31.5994 35.6213 29.3978C35.6732 29.0653 36.0045 28.8989 36.6152 28.8988C37.2714 28.8987 37.5995 29.0816 37.5996 29.4474Z"
        fill="currentColor"
      />
      <path
        d="M49.6165 33.805C48.9603 33.8052 48.6321 33.619 48.6321 33.2465L48.6311 27.3503L41.0296 27.3516L41.0306 33.2479C41.0307 33.6204 40.7026 33.8066 40.0464 33.8068C39.3837 33.8069 39.0524 33.6207 39.0523 33.2482L39.0501 20.3483C39.05 19.9759 39.3814 19.7896 40.044 19.7895C40.7002 19.7894 41.0284 19.9755 41.0284 20.348L41.0294 26.2442L48.6309 26.2429L48.6299 20.3466C48.6298 19.9742 48.9579 19.7879 49.6141 19.7878C50.2768 19.7876 50.6082 19.9738 50.6082 20.3463L50.6104 33.2462C50.6105 33.6186 50.2792 33.8049 49.6165 33.805Z"
        fill="currentColor"
      />
      <path
        d="M63.0652 33.8026C62.5324 33.8027 62.1718 33.6764 61.9834 33.4237L54.0975 22.5705L54.0993 33.3852C54.0994 33.6646 53.8427 33.8043 53.3295 33.8044C52.8162 33.8045 52.5596 33.6649 52.5595 33.3855L52.5573 20.4457C52.5572 20.0067 52.947 19.7872 53.7267 19.787C54.2529 19.7869 54.6103 19.9132 54.7987 20.166L62.6944 31.0192L62.6926 20.2144C62.6925 19.9284 62.9491 19.7854 63.4624 19.7853C63.9756 19.7852 64.2323 19.9282 64.2323 20.2142L64.2345 33.1639C64.2346 33.5896 63.8448 33.8025 63.0652 33.8026Z"
        fill="currentColor"
      />
      <path
        d="M72.4501 33.801C70.41 33.8013 68.7727 33.1398 67.538 31.8165C66.3099 30.4865 65.6956 28.8105 65.6953 26.7885C65.6949 24.7599 66.3086 23.0871 67.5363 21.7699C68.7705 20.4461 70.4076 19.7841 72.4477 19.7837C74.4877 19.7833 76.1218 20.4448 77.35 21.7682C78.5846 23.0849 79.2021 24.7575 79.2025 26.7861C79.2028 28.8081 78.5859 30.4843 77.3517 31.8147C76.124 33.1385 74.4901 33.8006 72.4501 33.801ZM72.4499 32.6836C74.0286 32.6833 75.2175 32.0579 76.0164 30.8073C76.8218 29.5568 77.2244 28.2165 77.2241 26.7865C77.2239 25.3565 76.8209 24.0197 76.015 22.7761C75.2157 21.5258 74.0266 20.9008 72.4479 20.9011C70.8691 20.9014 69.677 21.5268 68.8716 22.7773C68.0727 24.0212 67.6734 25.3582 67.6736 26.7882C67.6738 28.2182 68.0736 29.5583 68.873 30.8086C69.6788 32.0589 70.8711 32.6839 72.4499 32.6836Z"
        fill="currentColor"
      />
      <path
        d="M89.1635 33.5585L81.6595 33.5599C80.9968 33.56 80.6654 33.3738 80.6653 33.0014L80.6632 20.3409C80.6631 19.9685 80.9944 19.7822 81.6571 19.782C82.3133 19.7819 82.6414 19.9681 82.6415 20.3406L82.6436 32.4423L89.1633 32.4412C89.826 32.441 90.1573 32.6272 90.1574 32.9997C90.1575 33.3721 89.8262 33.5584 89.1635 33.5585Z"
        fill="currentColor"
      />
      <path
        d="M97.6518 33.7965C95.6117 33.7968 93.9744 33.1353 92.7398 31.812C91.5116 30.482 90.8974 28.806 90.897 26.784C90.8967 24.7554 91.5103 23.0826 92.738 21.7654C93.9722 20.4416 95.6094 19.7795 97.6494 19.7792C99.6895 19.7788 101.324 20.4403 102.552 21.7637C103.786 23.0804 104.404 24.753 104.404 26.7816C104.405 28.8036 103.788 30.4798 102.553 31.8102C101.326 33.134 99.6918 33.7961 97.6518 33.7965ZM97.6516 32.6791C99.2304 32.6788 100.419 32.0534 101.218 30.8028C102.024 29.5523 102.426 28.212 102.426 26.782C102.426 25.352 102.023 24.0152 101.217 22.7716C100.417 21.5213 99.2284 20.8963 97.6496 20.8966C96.0708 20.8969 94.8787 21.5223 94.0733 22.7728C93.2744 24.0167 92.8751 25.3537 92.8753 26.7837C92.8756 28.2137 93.2754 29.5538 94.0747 30.8041C94.8805 32.0544 96.0728 32.6794 97.6516 32.6791Z"
        fill="currentColor"
      />
      <path
        d="M112.328 32.6765C113.387 32.6763 114.329 32.3768 115.154 31.778C116.2 31.0329 116.723 30.2447 116.723 29.4133L116.723 27.7372L112.376 27.738C111.713 27.7381 111.382 27.5519 111.382 27.1795C111.382 26.807 111.713 26.6207 112.376 26.6206L117.707 26.6196C118.363 26.6195 118.691 26.8057 118.691 27.1781L118.691 29.4129C118.692 29.9517 118.464 30.5204 118.01 31.1191C117.555 31.7178 117.12 32.1668 116.704 32.4662C115.457 33.351 113.998 33.7935 112.328 33.7938C110.256 33.7942 108.58 33.1693 107.299 31.9191C106.019 30.6623 105.379 28.9597 105.379 26.8114C105.378 24.6431 106.034 22.9303 107.346 21.673C108.665 20.4091 110.367 19.7769 112.453 19.7765C113.72 19.7763 114.987 20.102 116.254 20.7536C117.527 21.4052 118.313 22.406 118.613 23.7561C118.652 23.9025 118.671 24.0322 118.671 24.1452C118.671 24.511 118.343 24.694 117.687 24.6941C117.076 24.6942 116.745 24.5214 116.693 24.1755C116.55 23.1446 116.053 22.3399 115.201 21.7614C114.35 21.1829 113.44 20.8938 112.472 20.8939C110.822 20.8942 109.555 21.4864 108.672 22.6705C107.789 23.8545 107.347 25.2347 107.347 26.811C107.348 28.3541 107.77 29.7175 108.615 30.9013C109.466 32.085 110.704 32.6767 112.328 32.6765Z"
        fill="currentColor"
      />
      <path
        d="M125.748 33.7914C125.092 33.7916 124.764 33.6087 124.764 33.2429L124.763 27.1372L119.752 20.5135C119.7 20.4537 119.674 20.3838 119.674 20.304C119.674 19.9515 120.051 19.7752 120.805 19.775C121.266 19.775 121.584 19.888 121.76 20.1141L126.01 25.7502L130.238 20.0328C130.368 19.8598 130.622 19.7733 130.998 19.7732C131.544 19.7731 131.817 19.9094 131.817 20.1821C131.817 20.242 131.798 20.2952 131.759 20.3418L126.741 27.1368L126.742 33.2425C126.742 33.6084 126.411 33.7913 125.748 33.7914Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Svg13;
