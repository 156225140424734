/* eslint-disable max-len */
import React from 'react';

const BotProtection = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="62" viewBox="0 0 51 62" fill="none">
      <g clipPath="url(#clip0_13763_66011)">
        <path d="M49.6599 25.3918C49.5285 29.7688 49.4566 34.1478 49.2472 38.5206C48.9803 44.1232 46.408 48.57 41.8545 51.7193C37.613 54.653 33.1724 57.3013 28.816 60.0728C27.9804 60.6045 27.1305 61.1157 26.3031 61.6618C25.7509 62.0273 25.2541 62.0129 24.6915 61.6577C19.9533 58.6624 15.1678 55.7349 10.4685 52.68C6.44876 50.0666 3.42882 46.6196 2.26478 41.8259C2.00816 40.7687 1.79054 39.6765 1.7577 38.5966C1.57703 32.5444 1.46001 26.4902 1.3122 20.4359C1.29372 19.6907 1.3779 18.9578 2.29558 18.9537C3.19068 18.9516 3.30975 19.662 3.32617 20.4154C3.44935 26.367 3.53558 32.3206 3.74498 38.2681C3.9318 43.6038 6.58836 47.642 10.9037 50.5347C15.5291 53.6367 20.2982 56.5212 25.0241 59.4693C25.2623 59.6171 25.7591 59.6068 26.0013 59.4569C30.6986 56.5294 35.4307 53.6491 40.0437 50.5922C44.8888 47.3813 47.2702 42.8483 47.3277 36.9296C47.4037 29.235 47.7711 21.5425 48.0811 13.852C48.1263 12.7352 47.8615 12.1994 46.7837 11.7436C39.9616 8.85097 33.1868 5.85157 26.375 2.93428C25.8864 2.72488 25.1535 2.71461 24.6669 2.92197C17.7278 5.88852 10.8175 8.92077 3.88253 11.8935C3.09213 12.2322 2.77597 12.6593 2.88684 13.4866C2.94021 13.889 2.91147 14.3078 2.88684 14.7163C2.84988 15.3528 2.48445 15.8496 1.86856 15.7223C1.49286 15.6443 0.987829 15.1249 0.936505 14.7492C0.805114 13.774 0.780478 12.7516 0.940611 11.7867C1.01452 11.3392 1.53803 10.8054 1.98763 10.6063C7.76266 8.05031 13.5623 5.54567 19.3579 3.02872C21.0803 2.27938 22.8172 1.56084 24.5191 0.76839C25.1966 0.452231 25.7714 0.448125 26.4592 0.749913C33.9012 4.0121 41.3474 7.27018 48.8202 10.4667C49.859 10.9122 50.1629 11.4993 50.1136 12.571C49.9206 16.8432 49.8036 21.1175 49.6599 25.3918Z" fill="#ED6E0F"/>
        <path d="M44.8185 18.2167C44.5229 24.8766 44.2355 31.5385 43.9255 38.1984C43.7222 42.561 41.427 45.7308 37.9493 48.0712C34.0404 50.7031 30.0309 53.1893 26.0379 55.698C25.7032 55.9074 25.0381 55.9895 24.7363 55.7986C20.4148 53.0497 16.044 50.3623 11.8579 47.4142C8.24469 44.8685 6.65363 41.1855 6.5941 36.7305C6.49966 29.7566 6.25125 22.7826 6.01516 15.8107C5.9782 14.7082 6.3539 14.1621 7.36191 13.7351C13.1123 11.2982 18.8401 8.80589 24.5618 6.30125C25.2085 6.01794 25.7443 5.98099 26.4013 6.27046C32.1229 8.77715 37.8466 11.2797 43.5991 13.7105C44.7138 14.1806 45.1326 14.8109 44.954 15.9708C44.8391 16.7119 44.837 17.4695 44.7836 18.2209C44.796 18.2147 44.8083 18.2147 44.8185 18.2167ZM42.7943 18.9866C42.784 18.9866 42.7758 18.9846 42.7656 18.9846C42.8169 18.1654 42.8354 17.3422 42.9298 16.5292C43.0016 15.8969 42.8169 15.5458 42.1969 15.281C36.892 13.0124 31.6097 10.6905 26.2945 8.44456C25.812 8.24132 25.0832 8.28032 24.5885 8.48973C19.4026 10.6905 14.2497 12.9693 9.06589 15.1742C8.28165 15.5068 8.0086 15.8887 8.03734 16.7509C8.26112 23.3841 8.5239 30.0173 8.58754 36.6505C8.62655 40.7174 10.1827 43.9098 13.4736 46.1681C17.1916 48.7199 21.0245 51.1055 24.8369 53.5157C25.0956 53.6799 25.6827 53.5609 25.9907 53.3699C29.0722 51.4627 32.1496 49.5493 35.1798 47.562C36.4609 46.7224 37.6865 45.7739 38.8259 44.7495C40.7372 43.0311 41.7883 40.8303 41.9156 38.2559C42.2318 31.8321 42.5028 25.4083 42.7943 18.9866Z" fill="#ED6E0F"/>
        <path d="M27.53 18.913C29.2278 18.913 30.7634 18.9069 32.299 18.9151C35.6228 18.9295 37.7784 21.0523 37.7907 24.3473C37.8051 27.5623 37.8051 30.7772 37.7907 33.9922C37.7764 37.2277 35.6146 39.3874 32.377 39.3977C27.7948 39.4121 23.2105 39.41 18.6282 39.3977C15.3886 39.3895 13.2186 37.2256 13.2063 33.9983C13.1919 30.7834 13.194 27.5684 13.2063 24.3534C13.2186 21.0625 15.3763 18.9295 18.6919 18.913C19.8539 18.9069 21.0179 18.9151 22.1799 18.913C22.8451 18.911 23.3686 19.1697 23.3891 19.8759C23.4117 20.6458 22.8738 20.9229 22.1614 20.9229C20.9645 20.9209 19.7676 20.9147 18.5707 20.925C16.4972 20.9414 15.2264 22.1814 15.2182 24.2303C15.2039 27.513 15.2039 30.7957 15.2182 34.0805C15.2264 36.1273 16.4993 37.3817 18.5707 37.3858C23.1879 37.396 27.8051 37.396 32.4222 37.3858C34.5347 37.3817 35.7788 36.117 35.785 33.986C35.7953 30.7711 35.7953 27.5561 35.785 24.3411C35.7788 22.1568 34.5368 20.9332 32.336 20.925C30.5232 20.9168 28.7104 20.9106 26.8976 20.9291C25.9574 20.9393 25.5037 20.5287 25.5201 19.5762C25.5386 18.447 25.5345 17.3199 25.5201 16.1908C25.5119 15.4558 25.7172 14.8276 26.5548 14.8481C27.3945 14.8687 27.5505 15.5256 27.5382 16.2442C27.5197 17.09 27.53 17.9379 27.53 18.913Z" fill="#ED6E0F"/>
        <path d="M25.5428 31.2321C26.1895 31.2321 26.8403 31.1972 27.4849 31.2403C28.3081 31.2937 28.5791 31.7453 28.2281 32.4803C27.6984 33.593 26.7746 34.1761 25.5592 34.2459C24.4383 34.3095 23.2229 33.5171 22.7733 32.4988C22.4428 31.7494 22.683 31.2957 23.5001 31.2424C24.1775 31.1972 24.8612 31.2321 25.5428 31.2321Z" fill="#ED6E0F"/>
        <path d="M20.3204 26.5319C21.1745 26.5073 21.9094 27.1765 21.9628 28.0285C22.0182 28.9216 21.3099 29.6956 20.4148 29.7222C19.5197 29.7489 18.7704 29.0201 18.7725 28.123C18.7745 27.2648 19.4623 26.5565 20.3204 26.5319Z" fill="#ED6E0F"/>
        <path d="M32.2278 28.0999C32.2442 28.997 31.5052 29.7382 30.608 29.7238C29.7519 29.7115 29.0518 29.0135 29.0354 28.1553C29.019 27.2582 29.756 26.5191 30.6532 26.5314C31.5113 26.5458 32.2134 27.2438 32.2278 28.0999Z" fill="#ED6E0F"/>
      </g>
      <defs>
        <clipPath id="clip0_13763_66011">
          <rect width="49.6821" height="62" fill="white" transform="translate(0.65918)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default BotProtection;
