/* eslint-disable max-len */
import React from 'react';

const Svg11 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="40" viewBox="0 0 49 40" fill="none">
      <g clipPath="url(#clip0_1373_6750)">
        <path d="M38.2896 32.5655C39.3327 33.384 40.3353 34.173 41.3363 34.9564C45.819 29.8412 47.5907 21.4843 43.8034 14.0443C39.8024 6.18524 31.5836 2.66637 25.5369 2.56933C25.5369 3.72259 25.5264 4.87445 25.5565 6.02631C25.5565 6.12336 25.8244 6.28228 25.981 6.29775C29.346 6.61199 32.5431 7.82481 35.1902 9.7913C35.9007 10.3145 36.0317 10.9277 35.5801 11.5268C35.0984 12.1681 34.3292 12.2385 33.572 11.6843C32.066 10.5729 30.3476 9.73913 28.5083 9.22733C19.7672 6.7886 10.6663 12.1921 9.39134 20.632C8.77117 24.7331 9.90614 28.4193 12.7225 31.6344C13.5474 32.5767 13.5098 33.1182 12.5178 33.903C10.999 35.1041 9.48167 36.3094 7.95683 37.5034C7.13194 38.1504 6.46209 38.0913 5.77569 37.3234C1.68436 32.7469 -0.261966 27.4672 0.031562 21.5208C0.540344 11.2244 8.5785 2.54683 19.4496 0.455485C32.2971 -2.0156 45.0979 5.72956 47.8797 17.7755C49.5566 25.0355 47.7291 31.5739 42.6955 37.3248C42.0302 38.0843 41.3272 38.1236 40.5114 37.4809L35.8571 33.8172C35.0081 33.1491 34.9418 32.5514 35.6584 31.7427C38.1857 28.9116 39.4005 25.6501 39.2003 21.9723C39.1401 20.8472 38.8345 19.722 38.6072 18.6096C38.4326 17.7474 39.1069 17.0147 40.0372 17.1117C40.692 17.1806 40.993 17.5815 41.1586 18.137C42.1268 21.2955 42.057 24.6528 40.9584 27.7738C40.3541 29.4962 39.4526 31.1147 38.2896 32.5655ZM2.65675 21.4547C3.91064 21.4547 5.0652 21.4393 6.22878 21.4646C6.64273 21.473 6.72401 21.3057 6.75562 20.9723C6.91363 19.0808 7.47345 17.2377 8.40238 15.5506C9.11137 14.2848 9.97841 13.1034 10.7416 11.9347L7.9809 9.35391C4.80778 12.7799 3.01049 16.7812 2.65675 21.4533V21.4547ZM22.9283 2.50464C17.9458 2.83656 13.6543 4.513 9.96938 7.49321L12.8143 10.1485C16.6588 7.52415 17.5439 7.17958 22.6152 6.30057C22.7341 6.27947 22.9162 6.12055 22.9162 6.01928C22.9358 4.87305 22.9283 3.7226 22.9283 2.50464ZM6.67132 23.916H2.64622C2.90503 27.9469 4.45362 31.8123 7.0913 35.0112C8.14499 34.18 9.14147 33.3882 10.1726 32.5725C8.15392 30.0544 6.94233 27.0524 6.67885 23.916H6.67132Z" fill="#031B59"/>
        <path d="M24.6864 30.0172C22.2374 30.0172 20.5966 28.9413 19.9779 27.3253C19.3502 25.684 19.8575 23.8093 21.3793 22.7882C24.5931 20.6279 27.852 18.5239 31.1139 16.4228C33.3237 14.9995 35.5665 13.6226 37.7928 12.2204C38.2685 11.9194 38.7577 11.8266 39.2665 12.1177C39.7377 12.3877 39.995 12.7928 39.8957 13.3132C39.8412 13.5698 39.7226 13.8107 39.5495 14.0164C35.6177 18.7607 31.6799 23.5003 27.7361 28.2353C26.8088 29.3492 25.6378 30.0116 24.6864 30.0172ZM32.5711 18.5675L32.4777 18.4832C31.5023 19.1006 30.5209 19.7067 29.556 20.3382C27.3613 21.7756 25.1576 23.2017 22.9975 24.6827C22.0943 25.3015 22.0703 26.3394 22.8139 27.0736C23.5575 27.8077 24.6759 27.8077 25.4285 27.0173C25.9268 26.4955 26.3934 25.9456 26.857 25.3943C28.7657 23.1197 30.6704 20.8441 32.5711 18.5675Z" fill="#031B59"/>
        <path d="M24.2651 37.5753C27.8516 37.5753 31.4382 37.5753 35.0247 37.5753C35.8361 37.5753 36.3087 37.9115 36.4156 38.5331C36.5556 39.3516 36.0468 39.9395 35.1557 39.993C35.0609 39.993 34.9675 39.993 34.8727 39.993H13.5821C12.6308 39.993 12.1205 39.6399 12.0407 38.9424C11.9489 38.1337 12.4532 37.5852 13.3383 37.5767C14.7276 37.5641 16.117 37.5767 17.5064 37.5767L24.2651 37.5753Z" fill="#031B59"/>
        <path d="M24.2365 35.3884C22.7312 35.3884 21.2365 35.4066 19.7372 35.3813C18.7422 35.3644 18.1777 34.4446 18.6173 33.6163C18.7174 33.4155 18.8793 33.2472 19.0822 33.1327C19.2852 33.0183 19.52 32.9628 19.7568 32.9735C22.7387 32.9651 25.7192 32.9595 28.7011 32.9735C29.5079 32.9735 30.0017 33.4953 29.9821 34.2168C29.964 34.92 29.4718 35.3757 28.68 35.3827C27.1988 35.3982 25.7177 35.3827 24.2365 35.3827V35.3884Z" fill="#031B59"/>
      </g>
      <defs>
        <clipPath id="clip0_1373_6750">
          <rect width="48.4848" height="40" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Svg11 ;
