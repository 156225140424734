/* eslint-disable max-len */
import React from "react";

const Svg20 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
    >
      <path
        d="M20 38C10.35 38 2.5 30.15 2.5 20.5C2.5 10.85 10.35 3 20 3C29.65 3 37.5 10.85 37.5 20.5C37.5 30.15 29.65 38 20 38ZM20 5.5C11.725 5.5 5 12.225 5 20.5C5 28.775 11.725 35.5 20 35.5C28.275 35.5 35 28.775 35 20.5C35 12.225 28.275 5.5 20 5.5Z"
        fill="#031B59"
      />
      <path
        d="M25.0001 26.75C24.7751 26.75 24.5501 26.7 24.3501 26.575L18.1001 22.825C17.915 22.7138 17.7622 22.556 17.6569 22.3674C17.5517 22.1788 17.4976 21.966 17.5001 21.75V11.75C17.5001 11.05 18.0501 10.5 18.7501 10.5C19.4501 10.5 20.0001 11.05 20.0001 11.75V21.05L25.6501 24.425C25.8828 24.5676 26.0627 24.7823 26.1624 25.0363C26.2622 25.2904 26.2764 25.57 26.2029 25.8329C26.1294 26.0958 25.9722 26.3275 25.7552 26.493C25.5381 26.6585 25.273 26.7487 25.0001 26.75Z"
        fill="#031B59"
      />
    </svg>
  );
};

export default Svg20;
