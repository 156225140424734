/* eslint-disable max-len */
import React from "react";

const SvgSocialNet = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15123_72288)">
        <path
          d="M13.2939 6.98384C13.184 6.98384 13.0778 6.92571 13.0205 6.82294L10.8081 2.83536C10.7239 2.68441 10.7786 2.4941 10.9296 2.41044C11.0811 2.32667 11.2709 2.38103 11.3543 2.53198L13.5668 6.51944C13.6509 6.67039 13.5962 6.86071 13.4452 6.94448C13.3971 6.97103 13.3452 6.98384 13.2939 6.98384Z"
          fill="#031B59"
        />
        <path
          d="M9.68758 13.4296H6.56254C6.39008 13.4296 6.25 13.29 6.25 13.1172C6.25 12.9444 6.39008 12.8047 6.56254 12.8047H9.68758C9.86038 12.8047 10 12.9444 10 13.1172C10 13.29 9.86038 13.4296 9.68758 13.4296Z"
          fill="#031B59"
        />
        <path
          d="M1.70573 6.98354C1.65446 6.98354 1.60228 6.97072 1.55444 6.94451C1.40349 6.86074 1.34913 6.67043 1.4329 6.51948L3.64539 2.53201C3.72916 2.38107 3.91913 2.32671 4.07042 2.41036C4.22137 2.49413 4.27573 2.68445 4.19196 2.8354L1.97947 6.82286C1.92225 6.9254 1.81571 6.98354 1.70573 6.98354Z"
          fill="#031B59"
        />
        <path
          d="M3.91879 12.609C3.80881 12.609 3.70227 12.5509 3.64539 12.4481L1.4329 8.46051C1.34913 8.30956 1.40349 8.11925 1.55444 8.03582C1.70573 7.95113 1.8957 8.00652 1.97947 8.15747L4.19196 12.1449C4.27573 12.2959 4.22137 12.4862 4.07042 12.5696C4.02224 12.5965 3.97006 12.609 3.91879 12.609Z"
          fill="#031B59"
        />
        <path
          d="M11.0811 12.6088C11.0299 12.6088 10.978 12.5963 10.9299 12.5694C10.7793 12.4857 10.7245 12.2957 10.8083 12.1447L13.0208 8.15727C13.1046 8.00655 13.294 7.95128 13.4455 8.03562C13.5961 8.11939 13.6508 8.30936 13.567 8.46031L11.3545 12.4479C11.2973 12.5507 11.1911 12.6088 11.0811 12.6088Z"
          fill="#031B59"
        />
        <path
          d="M8.43746 2.17574H5.31242C5.13996 2.17574 5 2.03578 5 1.86332C5 1.69074 5.13996 1.55078 5.31242 1.55078H8.43746C8.61027 1.55078 8.75 1.69074 8.75 1.86332C8.75 2.03578 8.61027 2.17574 8.43746 2.17574Z"
          fill="#031B59"
        />
        <path
          d="M13.7499 8.74215C13.0605 8.74215 12.5 8.18162 12.5 7.49223C12.5 6.80283 13.0605 6.24219 13.7499 6.24219C14.4393 6.24219 15 6.80283 15 7.49223C15 8.18162 14.4393 8.74215 13.7499 8.74215ZM13.7499 6.86715C13.4052 6.86715 13.125 7.14753 13.125 7.49223C13.125 7.83692 13.4052 8.11719 13.7499 8.11719C14.0946 8.11719 14.375 7.83692 14.375 7.49223C14.375 7.14753 14.0946 6.86715 13.7499 6.86715Z"
          fill="#031B59"
        />
        <path
          d="M1.25004 8.74215C0.560646 8.74215 0 8.18162 0 7.49223C0 6.80283 0.560646 6.24219 1.25004 6.24219C1.93943 6.24219 2.49996 6.80283 2.49996 7.49223C2.49996 8.18162 1.93943 8.74215 1.25004 8.74215ZM1.25004 6.86715C0.905342 6.86715 0.624962 7.14753 0.624962 7.49223C0.624962 7.83692 0.905342 8.11719 1.25004 8.11719C1.59473 8.11719 1.875 7.83692 1.875 7.49223C1.875 7.14753 1.59473 6.86715 1.25004 6.86715Z"
          fill="#031B59"
        />
        <path
          d="M4.37492 14.3671C3.68553 14.3671 3.125 13.8066 3.125 13.1172C3.125 12.4278 3.68553 11.8672 4.37492 11.8672C5.06432 11.8672 5.62496 12.4278 5.62496 13.1172C5.62496 13.8066 5.06432 14.3671 4.37492 14.3671ZM4.37492 12.4921C4.03023 12.4921 3.74996 12.7725 3.74996 13.1172C3.74996 13.4619 4.03023 13.7422 4.37492 13.7422C4.71962 13.7422 5 13.4619 5 13.1172C5 12.7725 4.71962 12.4921 4.37492 12.4921Z"
          fill="#031B59"
        />
        <path
          d="M10.625 14.3671C9.93565 14.3671 9.375 13.8066 9.375 13.1172C9.375 12.4278 9.93565 11.8672 10.625 11.8672C11.3144 11.8672 11.875 12.4278 11.875 13.1172C11.875 13.8066 11.3144 14.3671 10.625 14.3671ZM10.625 12.4921C10.2803 12.4921 9.99996 12.7725 9.99996 13.1172C9.99996 13.4619 10.2803 13.7422 10.625 13.7422C10.9697 13.7422 11.25 13.4619 11.25 13.1172C11.25 12.7725 10.9697 12.4921 10.625 12.4921Z"
          fill="#031B59"
        />
        <path
          d="M4.37492 3.11324C3.68553 3.11324 3.125 2.55271 3.125 1.86332C3.125 1.17393 3.68553 0.613281 4.37492 0.613281C5.06432 0.613281 5.62496 1.17393 5.62496 1.86332C5.62496 2.55271 5.06432 3.11324 4.37492 3.11324ZM4.37492 1.23824C4.03023 1.23824 3.74996 1.51862 3.74996 1.86332C3.74996 2.20802 4.03023 2.48828 4.37492 2.48828C4.71962 2.48828 5 2.20802 5 1.86332C5 1.51862 4.71962 1.23824 4.37492 1.23824Z"
          fill="#031B59"
        />
        <path
          d="M10.625 3.11324C9.93565 3.11324 9.375 2.55271 9.375 1.86332C9.375 1.17393 9.93565 0.613281 10.625 0.613281C11.3144 0.613281 11.875 1.17393 11.875 1.86332C11.875 2.55271 11.3144 3.11324 10.625 3.11324ZM10.625 1.23824C10.2803 1.23824 9.99996 1.51862 9.99996 1.86332C9.99996 2.20802 10.2803 2.48828 10.625 2.48828C10.9697 2.48828 11.25 2.20802 11.25 1.86332C11.25 1.51862 10.9697 1.23824 10.625 1.23824Z"
          fill="#031B59"
        />
        <path
          d="M7.49996 7.49219C6.63845 7.49219 5.9375 6.79124 5.9375 5.92961V5.30465C5.9375 4.44314 6.63845 3.74219 7.49996 3.74219C8.36147 3.74219 9.06242 4.44314 9.06242 5.30465V5.92961C9.06242 6.79124 8.36147 7.49219 7.49996 7.49219ZM7.49996 4.36715C6.98303 4.36715 6.56246 4.78772 6.56246 5.30465V5.92961C6.56246 6.44654 6.98303 6.86711 7.49996 6.86711C8.01689 6.86711 8.43746 6.44654 8.43746 5.92961V5.30465C8.43746 4.78772 8.01689 4.36715 7.49996 4.36715Z"
          fill="#031B59"
        />
        <path
          d="M8.43807 10.6133H6.56193C5.50724 10.6131 4.80812 9.92618 4.77905 9.89677C4.72034 9.83829 4.6875 9.75898 4.6875 9.67578V8.59306C4.6875 8.38306 4.79153 8.18862 4.96628 8.07269L6.5625 7.00862V6.86328H7.18746V7.17582C7.18746 7.28019 7.13528 7.37769 7.04842 7.43583L5.31246 8.59306V9.53296C5.50003 9.68116 5.96066 9.98798 6.56216 9.98832H8.43807C9.0453 9.98798 9.50157 9.68242 9.68754 9.53422V8.59306L7.95158 7.43583C7.86472 7.37769 7.81254 7.28019 7.81254 7.17582V6.86328H8.4375V7.00862L10.0341 8.07304C10.2085 8.18931 10.3125 8.38363 10.3125 8.59306V9.67578C10.3125 9.75898 10.2797 9.83829 10.2209 9.89677C10.1915 9.92618 9.49276 10.6131 8.43807 10.6133Z"
          fill="#031B59"
        />
        <path
          d="M5.6253 4.05104C5.52436 4.05104 5.42526 4.00229 5.36495 3.91199L4.73998 2.97449C4.64431 2.8311 4.68311 2.63666 4.8265 2.54111C4.97001 2.44486 5.16399 2.48389 5.26 2.62762L5.88497 3.56512C5.98052 3.70863 5.94184 3.90295 5.79845 3.99863C5.745 4.03422 5.68469 4.05104 5.6253 4.05104Z"
          fill="#031B59"
        />
        <path
          d="M9.37484 4.05104C9.31545 4.05104 9.25514 4.03422 9.20169 3.99863C9.05795 3.90295 9.01927 3.70886 9.11517 3.56512L9.74014 2.62762C9.83547 2.48389 10.0296 2.44486 10.1736 2.54111C10.3174 2.63666 10.3562 2.83076 10.2602 2.97449L9.63519 3.91199C9.57523 4.00229 9.47578 4.05104 9.37484 4.05104Z"
          fill="#031B59"
        />
        <path
          d="M10.0005 12.4883C9.89955 12.4883 9.80022 12.4395 9.74014 12.3492L9.11517 11.4117C9.01927 11.268 9.05795 11.0743 9.20169 10.9784C9.34554 10.8827 9.53952 10.9211 9.63519 11.0649L10.2602 12.0024C10.3562 12.1461 10.3174 12.3399 10.1736 12.4359C10.1202 12.4711 10.0599 12.4883 10.0005 12.4883Z"
          fill="#031B59"
        />
        <path
          d="M4.99965 12.4883C4.94026 12.4883 4.87995 12.4711 4.8265 12.4359C4.68311 12.3399 4.64431 12.1461 4.73998 12.0024L5.36495 11.0649C5.46062 10.9211 5.65437 10.8827 5.79845 10.9784C5.94184 11.0743 5.98052 11.268 5.88497 11.4117L5.26 12.3492C5.19969 12.4395 5.10059 12.4883 4.99965 12.4883Z"
          fill="#031B59"
        />
        <path
          d="M12.8125 7.80074H11.25C11.0772 7.80074 10.9375 7.66078 10.9375 7.48832C10.9375 7.31574 11.0772 7.17578 11.25 7.17578H12.8125C12.9853 7.17578 13.125 7.31574 13.125 7.48832C13.125 7.66078 12.9853 7.80074 12.8125 7.80074Z"
          fill="#031B59"
        />
        <path
          d="M3.75 7.80074H2.18754C2.01496 7.80074 1.875 7.66078 1.875 7.48832C1.875 7.31574 2.01496 7.17578 2.18754 7.17578H3.75C3.92246 7.17578 4.06254 7.31574 4.06254 7.48832C4.06254 7.66078 3.92246 7.80074 3.75 7.80074Z"
          fill="#031B59"
        />
      </g>
      <defs>
        <clipPath id="clip0_15123_72288">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgSocialNet;
