/* eslint-disable max-len */
import React from 'react';

const EmployBenifitSvvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="58" height="70" viewBox="0 0 58 70" fill="none">
      <g clipPath="url(#clip0_13020_11117)">
        <path d="M28.928 69.9958C26.3346 69.9958 23.7412 70.0088 21.1478 69.9958C16.8594 69.9697 14.5901 66.0642 16.7519 62.3341C19.4676 57.6468 23.5652 55.1128 28.9614 55.1016C34.402 55.0904 38.5181 57.6449 41.2449 62.3789C43.3697 66.0623 41.1042 69.9715 36.8787 70.0051C34.2335 70.0126 31.5807 69.9958 28.928 69.9958ZM28.9929 68.3351C31.7086 68.3351 34.4261 68.3705 37.1436 68.3239C39.6037 68.281 41.1857 65.8981 40.1205 63.7784C37.8383 59.2515 34.1056 56.7959 29.0911 56.7418C24.2747 56.6877 20.5698 58.9642 18.1616 63.1962C16.6519 65.8645 18.1616 68.3239 21.2089 68.3351C23.8079 68.3444 26.3995 68.3369 28.9929 68.3351Z" fill="#ED6E0F"/>
        <path d="M38.4457 15.8127C38.4413 17.6917 37.8847 19.5274 36.8461 21.0884C35.8075 22.6493 34.3335 23.8655 32.6097 24.5838C30.8859 25.3022 28.9896 25.4903 27.1598 25.1247C25.33 24.759 23.6487 23.8559 22.3277 22.5292C21.0067 21.2024 20.1052 19.5114 19.7368 17.6693C19.3685 15.8273 19.5497 13.9166 20.2577 12.1781C20.9658 10.4397 22.1689 8.95126 23.7155 7.90053C25.262 6.84981 27.0828 6.28379 28.9482 6.27384C34.1258 6.22533 38.442 10.5656 38.4457 15.8127ZM21.1957 15.7772C21.1753 20.1492 24.6839 23.7431 28.9593 23.7319C31.0364 23.7324 33.0286 22.9019 34.498 21.4232C35.9674 19.9445 36.7937 17.9385 36.7951 15.8463C36.8192 11.5191 33.2625 7.91403 28.9816 7.92523C26.9216 7.94664 24.9522 8.78107 23.4964 10.2492C22.0406 11.7174 21.2145 13.7022 21.1957 15.7772Z" fill="#ED6E0F"/>
        <path d="M10.0338 56.6735C8.08878 56.6735 6.14372 56.5784 4.20236 56.6959C1.10137 56.8825 -1.24383 53.5798 0.72161 50.4617C2.97048 46.8921 6.16409 44.9571 10.3543 45.084C14.4964 45.2071 17.6103 47.2634 19.5887 50.9525C20.9577 53.5107 19.3072 56.5037 16.4266 56.6344C14.3019 56.7295 12.166 56.653 10.0394 56.653L10.0338 56.6735ZM9.99681 55.0595C11.9697 55.0595 13.9425 55.0875 15.9246 55.0501C17.9475 55.011 19.0274 53.4286 18.1272 51.7324C16.3599 48.4017 13.5368 46.6402 9.79116 46.7447C6.17149 46.8436 3.50954 48.6723 1.83864 51.9265C1.43666 52.7065 1.60524 53.4286 2.07576 54.1116C2.60926 54.8878 3.41137 55.0445 4.27275 55.0576C6.17521 55.0632 8.08508 55.0595 9.99681 55.0595Z" fill="#ED6E0F"/>
        <path d="M47.9174 56.6751C45.9723 56.6751 44.0273 56.6957 42.0841 56.6751C38.6292 56.6304 36.8787 53.6056 38.6089 50.6014C40.7225 46.9348 43.9624 45.024 48.1415 45.0875C52.2892 45.149 55.4365 47.1736 57.4482 50.8365C58.9524 53.5739 57.2278 56.5651 54.1138 56.6602C52.0483 56.7237 49.9792 56.6733 47.9118 56.6733L47.9174 56.6751ZM47.8804 55.0611C49.7606 55.0611 51.6408 55.0611 53.521 55.0611C54.5473 55.0611 55.4698 54.8987 56.0644 53.8948C56.7017 52.8163 56.233 51.93 55.6791 51.0455C54.8532 49.7077 53.699 48.6069 52.3278 47.8492C50.9567 47.0915 49.4148 46.7025 47.8509 46.7197C46.2871 46.737 44.754 47.1599 43.3996 47.9477C42.0452 48.7354 40.9152 49.8615 40.1186 51.2172C39.5999 52.083 39.2887 52.9525 39.8556 53.921C40.3946 54.8409 41.2597 55.0592 42.2304 55.0592L47.8804 55.0611Z" fill="#ED6E0F"/>
        <path d="M34.8048 43.698C34.7233 44.9015 34.7715 46.1331 34.5325 47.3049C33.9342 50.2625 31.3333 52.0706 28.3101 51.7702C25.5315 51.4921 23.2937 49.0328 23.2733 46.1331C23.2585 44.1495 23.1603 42.1343 23.4586 40.19C23.9032 37.3182 26.63 35.3179 29.3049 35.5773C32.4541 35.8833 34.677 38.2456 34.7214 41.3618C34.7344 42.138 34.7214 42.9161 34.7214 43.6924L34.8048 43.698ZM33.0672 43.6775C33.0672 42.8695 33.082 42.0615 33.0672 41.2517C33.0071 40.2016 32.5491 39.2148 31.7877 38.4949C31.0263 37.775 30.0196 37.377 28.9754 37.383C27.9312 37.389 26.9291 37.7985 26.1759 38.5271C25.4227 39.2556 24.9758 40.2476 24.9276 41.2984C24.9072 42.9161 24.9035 44.5339 24.9276 46.1499C24.9555 47.2195 25.3918 48.2372 26.1457 48.991C26.8996 49.7449 27.9131 50.1769 28.9752 50.1972C31.1277 50.2177 33.0079 48.3909 33.0672 46.1965C33.0857 45.3587 33.0653 44.5265 33.0672 43.6775Z" fill="#ED6E0F"/>
        <path d="M43.4977 36.7545C43.4977 35.9465 43.3958 35.1236 43.5162 34.3287C43.8867 31.9664 45.7058 30.4102 47.975 30.4102C50.2128 30.4102 52.0745 31.9197 52.3542 34.2708C52.5574 35.9649 52.5437 37.6782 52.3135 39.3687C51.9819 41.7497 49.9053 43.1809 47.4823 42.998C45.3242 42.8357 43.5514 40.9118 43.4588 38.613C43.4329 37.9916 43.4588 37.3702 43.4588 36.747L43.4977 36.7545ZM50.8408 36.7545C50.8408 36.135 50.8686 35.5117 50.8408 34.8885C50.7519 33.252 49.4125 31.9738 47.8769 32.0373C47.1293 32.0678 46.4227 32.3899 45.9066 32.9355C45.3905 33.4812 45.1053 34.2076 45.1112 34.9613C45.0908 36.1088 45.0945 37.2564 45.1112 38.4058C45.1315 40.102 46.3116 41.341 47.9047 41.3671C49.5292 41.3914 50.7833 40.1412 50.8389 38.4245C50.8574 37.8703 50.8352 37.3124 50.8352 36.7526L50.8408 36.7545Z" fill="#ED6E0F"/>
        <path d="M14.5113 36.7518C14.5113 37.4982 14.5872 38.2539 14.4964 38.991C14.2074 41.2973 12.4198 42.9543 10.2358 43.0252C7.89617 43.1017 5.94556 41.6108 5.64361 39.2037C5.43485 37.5103 5.4448 35.7967 5.67324 34.1059C5.99371 31.7454 8.05364 30.2732 10.384 30.443C11.4878 30.5228 12.5231 31.012 13.2895 31.8161C14.056 32.6202 14.4992 33.6821 14.5335 34.7963C14.5631 35.4475 14.5335 36.1024 14.5335 36.7555L14.5113 36.7518ZM12.8811 36.7238C12.8811 36.1342 12.8922 35.5427 12.8811 34.9549C12.8403 33.3427 11.6474 32.0981 10.1024 32.0421C8.62048 31.9861 7.24597 33.2307 7.16817 34.8112C7.10334 36.0801 7.10519 37.3564 7.16817 38.6271C7.20047 39.3808 7.52607 40.0913 8.07452 40.605C8.62297 41.1187 9.35015 41.3942 10.0987 41.372C11.6585 41.3402 12.8422 40.1236 12.8774 38.4928C12.8941 37.9031 12.8811 37.3135 12.8811 36.7238Z" fill="#ED6E0F"/>
        <path d="M14.9624 15.0229C15.2681 15.0229 15.6311 14.9184 15.8664 15.049C16.1609 15.2132 16.5388 15.5696 16.537 15.8402C16.5351 16.1108 16.1517 16.5586 15.8664 16.6239C15.2984 16.7581 14.7077 16.762 14.1381 16.6351C13.7917 16.5511 13.5286 16.1126 13.2285 15.8346C13.5305 15.5584 13.8009 15.2263 14.1547 15.0322C14.3659 14.9128 14.6938 15.0098 14.9698 15.0098L14.9624 15.0229Z" fill="#ED6E0F"/>
        <path d="M43.0961 16.6676C42.7905 16.6676 42.4237 16.7721 42.1921 16.6415C41.8754 16.4549 41.4512 16.0929 41.4512 15.8167C41.4512 15.5405 41.905 15.1263 42.2274 15.0461C42.7644 14.9117 43.326 14.9117 43.8631 15.0461C44.2076 15.1394 44.4596 15.5779 44.7522 15.8634C44.4629 16.1538 44.1486 16.418 43.813 16.6527C43.6278 16.759 43.3332 16.6713 43.0887 16.6713L43.0961 16.6676Z" fill="#ED6E0F"/>
        <path d="M38.2734 7.25469C37.7028 7.17072 37.2842 6.7061 37.5843 6.25267C38.0566 5.538 38.7643 4.96514 39.4367 4.42401C39.5164 4.36057 40.2092 4.81027 40.3315 5.13122C40.5204 5.6145 38.8495 7.26589 38.2734 7.25469Z" fill="#ED6E0F"/>
        <path d="M20.4707 6.58905C20.3985 6.69728 20.2855 7.03502 20.1002 7.10219C19.8224 7.19736 19.3907 7.20669 19.174 7.04622C18.6621 6.65623 18.2059 6.19727 17.818 5.68219C17.6569 5.46761 17.6569 5.03097 17.7532 4.74921C17.8199 4.56261 18.1755 4.40214 18.4164 4.37602C18.9276 4.32937 20.454 5.90238 20.4707 6.58905Z" fill="#ED6E0F"/>
        <path d="M18.4919 27.511C18.1325 27.2311 17.8083 27.1042 17.7509 26.8989C17.6676 26.6134 17.6713 26.1843 17.8343 25.966C18.1888 25.4563 18.6334 25.0167 19.1458 24.6691C19.4144 24.5012 19.9887 24.5702 20.2573 24.7661C20.4166 24.88 20.3869 25.5629 20.2017 25.798C19.7145 26.4101 19.1032 26.9157 18.4919 27.511Z" fill="#ED6E0F"/>
        <path d="M40.3805 26.6368C40.2953 26.7581 40.1841 27.0734 39.9766 27.1648C39.7377 27.2675 39.3246 27.2843 39.143 27.1387C38.6112 26.715 38.1284 26.2324 37.7037 25.7001C37.6316 25.5723 37.5888 25.4299 37.5786 25.2833C37.5683 25.1367 37.5908 24.9896 37.6444 24.8529C37.7296 24.657 38.0612 24.4797 38.3039 24.4555C38.8337 24.3845 40.3842 25.9501 40.3805 26.6368Z" fill="#ED6E0F"/>
        <path d="M29.8015 30.1986C29.7533 30.7081 30.0386 31.6914 29.0216 31.7101C27.9231 31.725 28.2343 30.6969 28.1862 30.0046C28.1658 29.7284 28.0954 29.4 28.2121 29.1836C28.3973 28.8514 28.7085 28.5939 28.966 28.3047C29.2439 28.6051 29.5811 28.8645 29.7793 29.2209C29.9015 29.428 29.8015 29.762 29.8015 30.1986Z" fill="#ED6E0F"/>
        <path d="M28.2019 1.62723C28.2019 1.31934 28.1037 0.961077 28.2279 0.716635C28.3816 0.410616 28.7151 0.007567 28.98 0.000103121C29.2449 -0.00736076 29.695 0.391956 29.7487 0.675583C29.8645 1.31281 29.8645 1.9659 29.7487 2.60313C29.6969 2.89422 29.2152 3.34952 29.0189 3.31593C28.7039 3.24689 28.389 2.86623 28.2168 2.53969C28.0889 2.29898 28.1908 1.93698 28.1908 1.62723H28.2019Z" fill="#ED6E0F"/>
        <path d="M31.8811 13.867C31.4439 14.2981 31.1808 14.7664 30.8937 14.7814C30.6066 14.7963 30.2491 14.4082 29.9471 14.1507C29.8786 14.0928 29.9471 13.8558 29.8804 13.8017C29.5655 13.5592 29.2043 13.1542 28.8986 13.186C28.593 13.2177 28.115 13.7215 28.1317 13.9921C28.1539 14.3391 28.5707 14.6843 28.8727 14.9773C28.9746 15.0781 29.2321 15.0071 29.4117 15.0445C30.5084 15.2684 31.2531 15.9103 31.5124 17.0149C31.7718 18.1196 31.3716 19.0078 30.5084 19.7299C30.2065 19.9818 30.0323 20.383 29.7526 20.6629C29.5377 20.8831 29.2469 21.1742 28.9912 21.1723C28.7356 21.1704 28.4577 20.8682 28.2503 20.6461C27.9928 20.3774 27.8427 19.9893 27.5612 19.7635C26.8943 19.2298 26.4126 18.5898 26.4497 17.7277C26.4645 17.4161 26.8294 16.8843 27.0462 16.8787C27.3722 16.8694 27.7297 17.2071 28.028 17.4572C28.1373 17.5486 28.0502 17.8975 28.1576 17.9703C28.4837 18.1924 28.882 18.5002 29.2135 18.4592C29.4784 18.4256 29.86 17.9367 29.8693 17.64C29.8786 17.3433 29.5247 17 29.2728 16.7332C29.1765 16.6305 28.919 16.6847 28.7375 16.6585C28.1992 16.5914 27.694 16.3609 27.289 15.9976C26.884 15.6343 26.5983 15.1554 26.4701 14.6246C26.233 13.5703 26.6683 12.5571 27.5815 11.863C27.8446 11.6633 27.9817 11.3032 28.2243 11.0681C28.467 10.833 28.7523 10.5326 29.0375 10.5083C29.2673 10.4934 29.547 10.8292 29.76 11.0513C29.9851 11.3097 30.1948 11.5814 30.388 11.8649C30.853 12.4937 31.3142 13.1113 31.8811 13.867Z" fill="#ED6E0F"/>
      </g>
      <defs>
        <clipPath id="clip0_13020_11117">
          <rect width="58" height="70" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmployBenifitSvvg;
