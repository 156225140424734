/* eslint-disable max-len */
import React from 'react';

const TransparentReportingSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="62" viewBox="0 0 63 62" fill="none">
      <g clipPath="url(#clip0_13708_5943)">
        <path d="M40.1119 34.0872C42.4683 36.7372 44.8305 39.4064 47.2178 42.0545C47.3781 42.2322 47.7335 42.2264 47.9865 42.3345C48.8209 42.6899 49.8349 42.8638 50.4511 43.4529C53.9876 46.831 57.4507 50.2883 60.8945 53.7611C62.8376 55.7196 62.8433 58.5627 61.0046 60.4111C59.1369 62.2904 56.3691 62.302 54.3565 60.3281C50.7736 56.8109 47.2197 53.2666 43.7315 49.6587C43.21 49.1198 43.1038 48.1753 42.8237 47.4124C42.7078 47.0956 42.7291 46.6803 42.5282 46.4486C40.3263 43.928 38.0955 41.4364 35.8685 38.9371C35.8086 38.8695 35.714 38.8328 35.6425 38.7864C23.8645 47.5707 10.5393 42.2805 5.07132 34.8888C-1.51498 25.9808 -0.761704 14.2588 7.03369 6.27024C14.1782 -1.05001 26.2266 -2.01188 34.7425 3.9351C42.9473 9.66383 47.664 22.4907 40.1119 34.0872ZM22.298 2.34358C11.5166 2.36482 2.78446 11.1375 2.83468 21.8977C2.8849 32.6444 11.6016 41.336 22.329 41.334C33.0582 41.3321 41.8657 32.4937 41.8271 21.7702C41.7865 11.0641 32.9984 2.32426 22.298 2.34358ZM57.3194 59.8201C58.7023 59.7023 59.5714 59.3565 60.0447 58.3811C60.5063 57.4309 60.4194 56.498 59.7704 55.6694C59.3764 55.1672 58.8974 54.7287 58.4435 54.2749C55.3551 51.1826 52.2686 48.0884 49.1724 45.0058C48.3226 44.1598 47.608 44.1733 46.7794 44.9961C46.3467 45.4268 45.9141 45.8575 45.4853 46.2902C44.6644 47.1169 44.649 47.8527 45.4814 48.6891C48.8615 52.0788 52.2357 55.4782 55.6564 58.8254C56.1721 59.3314 56.9273 59.5941 57.3194 59.8201ZM45.4969 43.3814C43.2293 40.8415 41.0023 38.3461 38.7464 35.8197C38.194 36.4088 37.7362 36.8955 37.2147 37.4479C39.4301 39.9357 41.6223 42.3964 43.8628 44.913C44.4268 44.3838 44.9348 43.9068 45.4969 43.3814Z" fill="#ED6E0F"/>
        <path d="M5.88758 41.3789C6.61381 41.3789 7.21642 41.3789 7.93107 41.3789C7.94652 41.7324 7.97742 42.0723 7.97742 42.4122C7.98128 45.3075 7.9697 48.2028 7.98322 51.1C7.99481 53.6031 9.06484 54.6519 11.5854 54.6539C22.9424 54.6558 34.2994 54.6539 45.6564 54.6539C46.0408 54.6539 46.4252 54.6539 46.8887 54.6539C46.8887 55.3569 46.8887 56.0059 46.8887 56.7688C46.5121 56.7688 46.1683 56.7688 45.8245 56.7688C34.5003 56.7688 23.1742 56.7707 11.85 56.7688C7.92527 56.7688 5.88951 54.7427 5.88758 50.8431C5.88565 47.7238 5.88758 44.6045 5.88758 41.3789Z" fill="#ED6E0F"/>
        <path d="M40.8574 7.50578C40.8574 6.76796 40.8574 6.16341 40.8574 5.39662C41.1626 5.39662 41.4658 5.39662 41.7691 5.39662C45.0506 5.39662 48.3341 5.39082 51.6157 5.39855C55.0151 5.40821 57.2015 7.39955 57.2189 10.7989C57.2749 21.8681 57.2382 32.9393 57.2324 44.0104C57.2324 44.1012 57.1937 44.1939 57.159 44.3523C56.5371 44.3523 55.9132 44.3523 55.1425 44.3523C55.1425 43.9969 55.1425 43.6261 55.1425 43.2552C55.1425 32.5704 55.1097 21.8875 55.1696 11.2026C55.1812 9.13593 54.1903 7.39182 51.4824 7.4826C48.3322 7.59076 45.1762 7.50771 42.0221 7.50771C41.6744 7.50578 41.3248 7.50578 40.8574 7.50578Z" fill="#ED6E0F"/>
        <path d="M41.7923 49.6055C41.7923 50.2834 41.7923 50.8822 41.7923 51.5408C31.1924 51.5408 20.6389 51.5408 10.0332 51.5408C10.0332 50.886 10.0332 50.2873 10.0332 49.6055C20.5983 49.6055 31.1538 49.6055 41.7923 49.6055Z" fill="#ED6E0F"/>
        <path d="M10.041 47.4332C10.041 46.7321 10.041 46.1314 10.041 45.4805C19.2522 45.4805 28.4054 45.4805 37.6397 45.4805C37.6397 46.1179 37.6397 46.7398 37.6397 47.4332C28.4517 47.4332 19.2753 47.4332 10.041 47.4332Z" fill="#ED6E0F"/>
        <path d="M43.9102 10.5898C47.0314 10.5898 50.002 10.5898 53.0363 10.5898C53.0363 11.2369 53.0363 11.8318 53.0363 12.5116C50.0252 12.5116 47.0005 12.5116 43.9102 12.5116C43.9102 11.8859 43.9102 11.2639 43.9102 10.5898Z" fill="#ED6E0F"/>
        <path d="M53.0457 15.7266C53.0457 16.3794 53.0457 17.0071 53.0457 17.6967C50.6893 17.6967 48.3658 17.6967 45.9746 17.6967C45.9746 17.0593 45.9746 16.4354 45.9746 15.7266C48.302 15.7266 50.6468 15.7266 53.0457 15.7266Z" fill="#ED6E0F"/>
        <path d="M45.9844 25.9609C48.3311 25.9609 50.6315 25.9609 53.0149 25.9609C53.0149 26.6041 53.0149 27.228 53.0149 27.9252C50.7145 27.9252 48.389 27.9252 45.9844 27.9252C45.9844 27.3226 45.9844 26.6988 45.9844 25.9609Z" fill="#ED6E0F"/>
        <path d="M53.0457 20.8984C53.0457 21.5204 53.0457 22.1153 53.0457 22.7797C50.6912 22.7797 48.3657 22.7797 45.9727 22.7797C45.9727 22.1751 45.9727 21.5822 45.9727 20.8984C48.3039 20.8984 50.6487 20.8984 53.0457 20.8984Z" fill="#ED6E0F"/>
        <path d="M53.0262 33.0489C50.6563 33.0489 48.3347 33.0489 45.959 33.0489C45.959 32.3961 45.959 31.8031 45.959 31.1406C48.3096 31.1406 50.6312 31.1406 53.0262 31.1406C53.0262 31.7413 53.0262 32.3343 53.0262 33.0489Z" fill="#ED6E0F"/>
        <path d="M47.1068 38.1866C47.0528 38.1151 47.0219 38.0939 47.018 38.0668C46.7476 36.2087 46.765 36.1875 48.6288 36.1875C50.0697 36.1875 51.5086 36.1875 53.0229 36.1875C53.0229 36.8963 53.0229 37.5144 53.0229 38.1866C51.0219 38.1866 49.048 38.1866 47.1068 38.1866Z" fill="#ED6E0F"/>
        <path d="M22.2641 39.2522C12.572 39.1865 4.8577 31.4452 4.87895 21.8091C4.9002 12.1344 12.7555 4.32937 22.4031 4.3989C32.0701 4.46843 39.7824 12.258 39.7284 21.898C39.6762 31.5862 31.8963 39.3178 22.2641 39.2522ZM37.7023 21.8072C37.6231 13.3377 30.9923 6.44432 22.266 6.45784C13.7289 6.47136 6.94948 13.3396 6.90699 21.811C6.8645 30.2535 13.8294 37.2203 22.3162 37.2222C30.8108 37.2261 37.737 30.2863 37.7023 21.8072Z" fill="#ED6E0F"/>
        <path d="M35.0251 21.8856C33.4336 23.9929 31.3785 25.4472 29.0221 26.4574C22.4397 29.2812 15.3164 27.7457 10.3719 22.5558C9.61087 21.7581 10.003 21.2734 10.565 20.7267C14.2754 17.1014 18.7197 15.4442 23.8902 15.9251C28.3905 16.3443 32.0429 18.4341 35.0251 21.8856ZM32.1858 21.8856C30.3915 19.8866 26.3895 17.9995 24.5314 18.2622C25.7483 19.2067 26.4977 20.3501 26.4494 21.9474C26.3992 23.5699 25.5281 24.6631 24.0679 25.6752C27.3185 25.1846 29.9028 23.933 32.1858 21.8856ZM12.3787 21.8296C14.6559 23.9426 17.2247 25.1421 20.2185 25.5322C18.9302 24.6476 18.1808 23.454 18.1518 21.9204C18.1228 20.3501 18.9051 19.1372 20.1393 18.1656C17.1648 18.5094 14.6848 19.7977 12.3787 21.8296ZM24.3615 21.8393C24.3653 20.748 23.4402 19.8132 22.3412 19.7996C21.2325 19.7861 20.3035 20.6765 20.2726 21.7813C20.2436 22.8919 21.2344 23.9117 22.3315 23.8982C23.3977 23.8866 24.3576 22.9112 24.3615 21.8393Z" fill="#ED6E0F"/>
        <path d="M29.4594 11.6367C29.4594 12.3069 29.4594 12.9096 29.4594 13.5836C24.7138 13.5836 20.003 13.5836 15.2188 13.5836C15.2188 12.9463 15.2188 12.3224 15.2188 11.6367C19.9315 11.6367 24.6443 11.6367 29.4594 11.6367Z" fill="#ED6E0F"/>
        <path d="M29.4458 30.1406C29.4458 30.7838 29.4458 31.3787 29.4458 32.0219C24.6886 32.0219 19.9816 32.0219 15.209 32.0219C15.209 31.3999 15.209 30.8089 15.209 30.1406C19.9256 30.1406 24.6326 30.1406 29.4458 30.1406Z" fill="#ED6E0F"/>
      </g>
      <defs>
        <clipPath id="clip0_13708_5943">
          <rect width="62" height="62" fill="white" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default TransparentReportingSvg;

