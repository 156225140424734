/* eslint-disable max-len */
import React from 'react';

const Svg09 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
    >
      <g clipPath="url(#clip0_12998_20466)">
        <path
          d="M3.88579 14.0024C3.76593 13.8368 3.62741 13.6822 3.52854 13.5048C3.196 12.9102 2.87559 12.312 2.55425 11.7129C2.44232 11.5039 2.43439 11.2907 2.57384 11.0859C2.71329 10.881 2.9171 10.8114 3.15869 10.8384C3.92824 10.9299 4.69778 11.0214 5.46638 11.1275C5.90945 11.1897 6.10627 11.6278 5.8824 12.0143C5.82644 12.1117 5.75974 12.2027 5.65387 12.3637C6.19349 12.4584 6.67993 12.5887 7.17523 12.623C8.93259 12.7456 10.4539 12.1894 11.7421 11.0118C11.9599 10.8124 12.2136 10.7776 12.4464 10.8965C12.5596 10.9499 12.651 11.0395 12.7054 11.1505C12.7599 11.2615 12.7743 11.3874 12.7463 11.5075C12.717 11.6326 12.6523 11.7471 12.5597 11.8377C11.3471 12.977 9.90734 13.631 8.22647 13.7792C7.17988 13.8693 6.12604 13.727 5.14318 13.363C5.10074 13.3479 5.05643 13.3383 5.01912 13.3278L4.4907 14.0024H3.88579Z"
          fill="#031B59"
        />
        <path
          d="M15.4998 7.09168C15.1701 7.59477 14.8376 8.09785 14.512 8.60597C14.3763 8.81727 14.2159 8.97688 13.9398 8.97185C13.6902 8.96774 13.5042 8.85843 13.4067 8.63387C13.104 7.93412 12.8004 7.23392 12.5117 6.53005C12.3209 6.06767 12.6418 5.65742 13.1511 5.69218L13.4715 5.71368C13.3526 5.34277 13.27 4.99884 13.1343 4.67641C12.4053 2.94579 11.1209 1.80286 9.30199 1.23849C8.98951 1.14153 8.80062 0.933894 8.79502 0.660398C8.79036 0.42852 8.948 0.179263 9.18446 0.119807C9.33293 0.0824613 9.48896 0.0853061 9.63592 0.128039C12.341 0.986946 13.9939 2.78983 14.6137 5.50879C14.6337 5.59706 14.6529 5.68578 14.6687 5.7585L15.4998 5.90257V7.09168Z"
          fill="#031B59"
        />
        <path
          d="M3.73072 2.42868C2.89737 3.13263 2.28148 4.05118 1.95271 5.08046C1.62394 6.10974 1.59539 7.2087 1.8703 8.25306C1.90795 8.40273 1.88411 8.56092 1.80394 8.69352C1.72376 8.82613 1.59367 8.92253 1.44169 8.96196C1.10262 9.04153 0.788743 8.8499 0.707591 8.49911C0.122117 5.91782 0.775062 3.68427 2.66642 1.79845C2.73732 1.72802 2.81334 1.66125 2.88796 1.59584C3.18039 1.33561 3.18458 1.33424 3.00082 0.978877C2.90008 0.784045 2.81427 0.592872 2.90008 0.369684C2.9859 0.146496 3.18318 -0.00534418 3.43083 0.000144047C4.19944 0.0170661 4.96992 0.0394751 5.73713 0.0632574C5.9596 0.0701177 6.16994 0.115397 6.31452 0.306113C6.47962 0.523812 6.48568 0.775813 6.31125 1.00449C5.85046 1.60774 5.38827 2.21053 4.91628 2.80554C4.6066 3.19612 4.10756 3.13986 3.8739 2.69989C3.82913 2.61437 3.78389 2.52884 3.73072 2.42868Z"
          fill="#031B59"
        />
        <path
          d="M6.11918 5.53665C6.09307 5.10674 6.20826 4.71936 6.50162 4.39601C6.92137 3.93592 7.44793 3.76121 8.05843 3.89567C8.63069 4.02144 9.04344 4.36903 9.20481 4.92425C9.38577 5.54762 9.36712 6.16917 8.90213 6.69329C8.51642 7.12778 8.01599 7.30843 7.43673 7.2069C6.8253 7.10033 6.40135 6.74955 6.20267 6.16688C6.13504 5.96793 6.12525 5.74977 6.08887 5.54031L6.11918 5.53665Z"
          fill="#031B59"
        />
        <path
          d="M7.70093 9.83372C6.89314 9.83372 6.08489 9.83372 5.27571 9.83372C4.93664 9.83372 4.68526 9.50214 4.81772 9.20989C4.88382 9.07952 4.97955 8.96577 5.09755 8.8774C5.54761 8.52341 6.07696 8.32263 6.62637 8.17262C6.65501 8.1693 6.68384 8.17719 6.70659 8.19457C6.88594 8.33509 7.09422 8.43583 7.31708 8.48983C7.53995 8.54383 7.7721 8.54983 7.99756 8.5074C8.24826 8.4625 8.48404 8.35843 8.68455 8.20417C8.71135 8.18149 8.74623 8.17014 8.78155 8.17262C9.3249 8.32126 9.84585 8.52021 10.295 8.86459C10.3922 8.93567 10.4766 9.02206 10.545 9.12025C10.5903 9.19115 10.6153 9.27272 10.6173 9.35636C10.6193 9.44001 10.5982 9.52264 10.5563 9.59554C10.5145 9.66844 10.4533 9.72892 10.3793 9.77059C10.3053 9.81227 10.2213 9.8336 10.1359 9.83235C9.38366 9.83601 8.63091 9.83235 7.87862 9.83235L7.70093 9.83372Z"
          fill="#031B59"
        />
      </g>
      <defs>
        <clipPath id="clip0_12998_20466">
          <rect
            width="15"
            height="14"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Svg09;
