/* eslint-disable max-len */
import React from "react";

const SvgWelness = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15123_72435)">
        <path
          d="M15.5623 11.5243C15.507 11.4875 14.2838 10.6805 13.0559 10.4907C13.3207 9.46387 13.3695 8.46801 13.3728 8.39863C13.3758 8.33277 13.351 8.26879 13.3043 8.22238C13.2579 8.17598 13.1951 8.15254 13.1281 8.15394C13.05 8.15746 11.3149 8.2416 10.051 8.80457C9.55573 7.5148 8.40167 6.2384 8.33956 6.17043C8.25073 6.0734 8.08245 6.0734 7.99362 6.17043C7.93151 6.23816 6.77768 7.5148 6.28221 8.80457C5.01823 8.2416 3.28315 8.15746 3.2051 8.15394C3.13784 8.15254 3.07526 8.17598 3.02885 8.22238C2.98221 8.26879 2.95737 8.33277 2.96042 8.39863C2.96346 8.46777 3.01245 9.46363 3.27729 10.4907C2.0494 10.6805 0.826197 11.4875 0.770885 11.5243C0.686041 11.5807 0.647604 11.6855 0.675494 11.7837C0.696588 11.8575 1.22159 13.5943 3.71346 13.5943H12.6197C15.1116 13.5943 15.6366 11.8575 15.6574 11.7837C15.6856 11.6855 15.6471 11.5807 15.5623 11.5243ZM12.8855 8.64121C12.8255 9.34246 12.5935 11.2533 11.8125 12.0343C11.1239 12.7229 9.55292 12.9849 8.71174 13.0782C9.27542 12.3771 10.276 10.9742 10.276 9.84426C10.276 9.65348 10.2471 9.45473 10.197 9.25316C11.1064 8.8348 12.3495 8.68738 12.8855 8.64121ZM8.16659 6.68629C8.6201 7.22512 9.80721 8.74105 9.80721 9.84426C9.80721 10.9475 8.6201 12.4634 8.16659 13.0022C7.71307 12.4634 6.52596 10.9472 6.52596 9.84426C6.52596 8.74129 7.71307 7.22512 8.16659 6.68629ZM6.1362 9.25316C6.08604 9.45473 6.05721 9.65348 6.05721 9.84426C6.05721 10.9742 7.05799 12.3771 7.6212 13.0779C6.78003 12.9844 5.20901 12.7226 4.52042 12.034C3.74042 11.254 3.50792 9.34246 3.44768 8.64098C3.98346 8.68715 5.22612 8.8341 6.1362 9.25316ZM1.19042 11.8146C1.55956 11.5925 2.50971 11.0661 3.40948 10.9442C3.58971 11.4952 3.84003 12.0167 4.18901 12.3654C4.52534 12.7018 5.02198 12.9469 5.55096 13.1255H3.71346C1.98987 13.1255 1.37182 12.1974 1.19042 11.8146ZM12.6197 13.1255H10.7822C11.3112 12.9469 11.8076 12.7018 12.1442 12.3654C12.4932 12.0164 12.7437 11.4952 12.9237 10.9442C13.8225 11.0661 14.7715 11.5913 15.1416 11.8139C14.9576 12.1948 14.3325 13.1255 12.6197 13.1255Z"
          fill="#031B59"
        />
        <path
          d="M8.03643 5.58563C8.0758 5.61188 8.12127 5.625 8.1665 5.625C8.21174 5.625 8.25721 5.61188 8.29658 5.58563C8.52322 5.43469 10.5103 4.07344 10.5103 2.8125C10.5103 2.03695 9.87955 1.40625 9.104 1.40625C8.75221 1.40625 8.42244 1.53563 8.1665 1.76813C7.91057 1.53563 7.5808 1.40625 7.229 1.40625C6.45346 1.40625 5.82275 2.03695 5.82275 2.8125C5.82275 4.07344 7.81002 5.43469 8.03643 5.58563ZM7.229 1.875C7.52502 1.875 7.7983 2.01375 7.97854 2.25586C8.06689 2.37445 8.26611 2.37445 8.35447 2.25586C8.53471 2.01375 8.80799 1.875 9.104 1.875C9.62104 1.875 10.0415 2.29547 10.0415 2.8125C10.0415 3.60938 8.79041 4.66266 8.16627 5.10586C7.54213 4.66289 6.29127 3.61055 6.29127 2.8125C6.2915 2.29547 6.71197 1.875 7.229 1.875Z"
          fill="#031B59"
        />
      </g>
      <defs>
        <clipPath id="clip0_15123_72435">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(0.666504)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgWelness;
