/* eslint-disable max-len */
import React from "react";

const SvgFinance = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.36219 8.18483C8.33444 8.17882 8.30597 8.17774 8.27786 8.18195C7.99613 8.14915 7.78361 7.9108 7.78313 7.62727C7.78313 7.49139 7.67296 7.38123 7.53708 7.38123C7.40121 7.38123 7.29104 7.49139 7.29104 7.62727C7.29176 8.10073 7.60845 8.51545 8.06497 8.64076V8.8868C8.06497 9.02268 8.17514 9.13284 8.31102 9.13284C8.44689 9.13284 8.55706 9.02268 8.55706 8.8868V8.65565C9.08399 8.54537 9.44212 8.0546 9.38673 7.51915C9.33123 6.98369 8.88011 6.57678 8.34177 6.57666C8.03326 6.57666 7.78313 6.32653 7.78313 6.01802C7.78313 5.7095 8.03326 5.45937 8.34177 5.45937C8.65029 5.45937 8.90041 5.7095 8.90041 6.01802C8.90041 6.15389 9.01058 6.26406 9.14646 6.26406C9.28233 6.26406 9.3925 6.15389 9.3925 6.01802C9.39178 5.52088 9.0435 5.09211 8.55706 4.98951V4.76557C8.55706 4.6297 8.44689 4.51953 8.31102 4.51953C8.17514 4.51953 8.06497 4.6297 8.06497 4.76557V5.00453C7.55727 5.14317 7.23049 5.6361 7.30041 6.15774C7.37033 6.67938 7.81544 7.06875 8.34177 7.06863C8.6462 7.06863 8.89453 7.31239 8.90005 7.6167C8.9057 7.92101 8.66638 8.17378 8.36219 8.18483Z"
        fill="#031B59"
      />
      <path
        d="M4.77393 6.84131C4.77393 8.81181 6.37129 10.4092 8.34179 10.4092C10.3123 10.4092 11.9097 8.81181 11.9097 6.84131C11.9097 4.8708 10.3123 3.27344 8.34179 3.27344C6.37225 3.2756 4.77621 4.87176 4.77393 6.84131ZM8.34179 3.76552C10.0406 3.76552 11.4176 5.14255 11.4176 6.84131C11.4176 8.54006 10.0406 9.91709 8.34179 9.91709C6.64304 9.91709 5.26601 8.54006 5.26601 6.84131C5.26793 5.14339 6.64388 3.76745 8.34179 3.76552Z"
        fill="#031B59"
      />
      <path
        d="M13.1227 11.0194L11.1953 11.93C10.9292 11.435 10.4188 11.12 9.85699 11.1042L8.07774 11.0553C7.79602 11.0472 7.51958 10.9759 7.26909 10.8465L7.08804 10.7527C6.16106 10.2695 5.05614 10.2707 4.13012 10.7558L4.14141 10.3456C4.14513 10.2098 4.03809 10.0966 3.90221 10.0929L1.95117 10.0392C1.81529 10.0354 1.70224 10.1425 1.69852 10.2784L1.58066 14.5598C1.57694 14.6957 1.68398 14.8089 1.81986 14.8126L3.7709 14.8663H3.77763C3.91098 14.8663 4.02007 14.7602 4.02367 14.627L4.02932 14.4222L4.53618 14.1508C4.73513 14.0437 4.96772 14.0183 5.18505 14.0798L8.21182 14.9293C8.2171 14.9308 8.22239 14.932 8.22779 14.9332C8.44657 14.979 8.66954 15.002 8.893 15.0018C9.36635 15.0021 9.83416 14.9001 10.2644 14.7026C10.275 14.6978 10.2852 14.6921 10.2949 14.6857L14.6834 11.8471C14.7928 11.7764 14.8281 11.6328 14.764 11.5194C14.4382 10.9413 13.7156 10.7211 13.1227 11.0194ZM2.07935 14.3275L2.18364 10.5379L3.64272 10.578L3.53843 14.3677L2.07935 14.3275ZM10.0427 14.2631C9.50823 14.5043 8.91126 14.5709 8.33676 14.4535L5.31816 13.6062C4.97841 13.5102 4.61475 13.55 4.30396 13.7171L4.04494 13.8558L4.11426 11.3362C4.93504 10.7903 5.9877 10.7342 6.86182 11.1896L7.04287 11.2834C7.35931 11.4468 7.70832 11.5369 8.06429 11.5473L9.84365 11.5961C10.3434 11.6106 10.7679 11.9664 10.8693 12.4561L8.2189 12.3829C8.08303 12.3792 7.96998 12.4864 7.96613 12.6221C7.96241 12.758 8.06957 12.8712 8.20533 12.8749L11.1302 12.9554H11.1371C11.2703 12.9553 11.3793 12.8492 11.3829 12.7161C11.3859 12.6092 11.3779 12.5021 11.3591 12.3969L13.336 11.4628C13.3379 11.462 13.3398 11.461 13.3417 11.46C13.6221 11.3181 13.9612 11.3656 14.1918 11.5791L10.0427 14.2631Z"
        fill="#031B59"
      />
      <path
        d="M8.58779 2.58345V0.246043C8.58779 0.110167 8.47762 0 8.34175 0C8.20587 0 8.0957 0.110167 8.0957 0.246043V2.58345C8.0957 2.71933 8.20587 2.8295 8.34175 2.8295C8.47762 2.8295 8.58779 2.71933 8.58779 2.58345Z"
        fill="#031B59"
      />
      <path
        d="M10.4335 2.58371V1.47651C10.4335 1.34064 10.3233 1.23047 10.1874 1.23047C10.0516 1.23047 9.94141 1.34064 9.94141 1.47651V2.58371C9.94141 2.71958 10.0516 2.82975 10.1874 2.82975C10.3233 2.82975 10.4335 2.71958 10.4335 2.58371Z"
        fill="#031B59"
      />
      <path
        d="M6.74257 2.58371V1.47651C6.74257 1.34064 6.63241 1.23047 6.49653 1.23047C6.36066 1.23047 6.25049 1.34064 6.25049 1.47651V2.58371C6.25049 2.71958 6.36066 2.82975 6.49653 2.82975C6.63241 2.82975 6.74257 2.71958 6.74257 2.58371Z"
        fill="#031B59"
      />
    </svg>
  );
};

export default SvgFinance;
