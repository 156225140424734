/* eslint-disable max-len */
import React from "react";

const SvgNews = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15123_72457)">
        <path
          d="M6.4696 11.4023H3.042C2.85457 11.4023 2.70264 11.5543 2.70264 11.7417C2.70264 11.9291 2.85457 12.0811 3.042 12.0811H6.4696C6.65704 12.0811 6.80897 11.9291 6.80897 11.7417C6.80897 11.5543 6.65704 11.4023 6.4696 11.4023Z"
          fill="#031B59"
        />
        <path
          d="M11.5941 11.4023H8.16651C7.97908 11.4023 7.82715 11.5543 7.82715 11.7417C7.82715 11.9291 7.97908 12.0811 8.16651 12.0811H11.5941C11.7815 12.0811 11.9335 11.9291 11.9335 11.7417C11.9335 11.5543 11.7815 11.4023 11.5941 11.4023Z"
          fill="#031B59"
        />
        <path
          d="M11.594 2.91797H3.042C2.85457 2.91797 2.70264 3.0699 2.70264 3.25734C2.70264 3.44477 2.85457 3.5967 3.042 3.5967H11.594C11.7814 3.5967 11.9334 3.44477 11.9334 3.25734C11.9334 3.0699 11.7814 2.91797 11.594 2.91797Z"
          fill="#031B59"
        />
        <path
          d="M11.5941 4.61719H8.16651C7.97908 4.61719 7.82715 4.76912 7.82715 4.95655C7.82715 5.14399 7.97908 5.29592 8.16651 5.29592H11.5941C11.7815 5.29592 11.9335 5.14399 11.9335 4.95655C11.9335 4.76912 11.7815 4.61719 11.5941 4.61719Z"
          fill="#031B59"
        />
        <path
          d="M11.5941 6.3125H8.16651C7.97908 6.3125 7.82715 6.46443 7.82715 6.65187C7.82715 6.8393 7.97908 6.99123 8.16651 6.99123H11.5941C11.7815 6.99123 11.9335 6.8393 11.9335 6.65187C11.9335 6.46443 11.7815 6.3125 11.5941 6.3125Z"
          fill="#031B59"
        />
        <path
          d="M11.5941 8.00781H8.16651C7.97908 8.00781 7.82715 8.15975 7.82715 8.34718C7.82715 8.53461 7.97908 8.68655 8.16651 8.68655H11.5941C11.7815 8.68655 11.9335 8.53461 11.9335 8.34718C11.9335 8.15975 11.7815 8.00781 11.5941 8.00781Z"
          fill="#031B59"
        />
        <path
          d="M11.5941 9.70703H8.16651C7.97908 9.70703 7.82715 9.85897 7.82715 10.0464C7.82715 10.2338 7.97908 10.3858 8.16651 10.3858H11.5941C11.7815 10.3858 11.9335 10.2338 11.9335 10.0464C11.9335 9.85897 11.7815 9.70703 11.5941 9.70703Z"
          fill="#031B59"
        />
        <path
          d="M6.4696 9.70703H3.042C2.85457 9.70703 2.70264 9.85897 2.70264 10.0464C2.70264 10.2338 2.85457 10.3858 3.042 10.3858H6.4696C6.65704 10.3858 6.80897 10.2338 6.80897 10.0464C6.80897 9.85897 6.65704 9.70703 6.4696 9.70703Z"
          fill="#031B59"
        />
        <path
          d="M6.4696 4.61719H3.042C2.85457 4.61719 2.70264 4.76912 2.70264 4.95655V8.38416C2.70264 8.57159 2.85457 8.72352 3.042 8.72352H6.4696C6.65704 8.72352 6.80897 8.57159 6.80897 8.38416V4.95655C6.80897 4.76912 6.65704 4.61719 6.4696 4.61719ZM6.13024 8.04479H3.38137V5.29592H6.13024V8.04479Z"
          fill="#031B59"
        />
        <path
          d="M14.9878 2.57965H13.9697V1.56155C13.9697 1.18729 13.6652 0.882812 13.2909 0.882812H1.34524C0.970984 0.882812 0.666504 1.18729 0.666504 1.56155V12.4213C0.666504 13.3569 1.4277 14.1181 2.36334 14.1181H13.9697C14.9053 14.1181 15.6665 13.3569 15.6665 12.4213V3.25838C15.6665 2.88412 15.362 2.57965 14.9878 2.57965ZM14.9878 12.4213C14.9878 12.9827 14.5311 13.4394 13.9697 13.4394H2.36334C1.80196 13.4394 1.34524 12.9827 1.34524 12.4213V1.56155H13.2909V11.9122C13.2909 12.38 13.6715 12.7606 14.1394 12.7606C14.3268 12.7606 14.4787 12.6087 14.4787 12.4213C14.4787 12.2338 14.3268 12.0819 14.1394 12.0819C14.0458 12.0819 13.9697 12.0058 13.9697 11.9122V3.25838H14.9878V12.4213Z"
          fill="#031B59"
        />
      </g>
      <defs>
        <clipPath id="clip0_15123_72457">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(0.666504)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgNews;
