/* eslint-disable max-len */
import React from 'react';

const FaceBookSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
      <path d="M37.1663 20.6296C37.1663 11.4296 29.6997 3.96289 20.4997 3.96289C11.2997 3.96289 3.83301 11.4296 3.83301 20.6296C3.83301 28.6962 9.56634 35.4129 17.1663 36.9629V25.6296H13.833V20.6296H17.1663V16.4629C17.1663 13.2462 19.783 10.6296 22.9997 10.6296H27.1663V15.6296H23.833C22.9163 15.6296 22.1663 16.3796 22.1663 17.2962V20.6296H27.1663V25.6296H22.1663V37.2129C30.583 36.3796 37.1663 29.2796 37.1663 20.6296Z" fill="white"/>
    </svg>
  );
};

export default FaceBookSvg;
