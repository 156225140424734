/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
import React from 'react';

const IncreaseSalesSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="58" height="55" viewBox="0 0 58 55" fill="none">
      <path d="M2.42794 20.3423C2.44304 10.4525 9.55241 2.0922 19.4539 0.317571C30.0394 -1.58115 40.4558 5.22433 42.9218 15.6493C43.8806 19.61 43.6159 23.7646 42.162 27.5746C40.7081 31.3847 38.132 34.6746 34.7674 37.0181C34.026 37.5369 33.3284 37.5085 32.898 36.9404C32.4268 36.3169 32.596 35.6472 33.3404 35.091C39.1602 30.7553 41.8934 24.9725 40.8681 17.8441C39.8352 10.6559 35.604 5.67734 28.6456 3.34206C19.7363 0.351959 10.3739 4.54558 6.43865 13.0121C2.24823 22.0227 6.52775 32.9262 15.7814 36.8133C15.9935 36.8906 16.1987 36.9856 16.3945 37.0974C16.9472 37.4472 17.1677 38.1349 16.8475 38.6567C16.5198 39.2024 16.0291 39.4581 15.3767 39.2159C12.9488 38.2999 10.7299 36.9133 8.85024 35.1373C6.81144 33.2397 5.18906 30.9468 4.08416 28.4014C2.97925 25.8561 2.41548 23.1128 2.42794 20.3423Z" fill="#ED6E0F"/>
      <path d="M37.3257 44.9622L53.6661 28.7798C53.4592 28.7618 53.2584 28.7319 53.0621 28.7319C51.1745 28.7319 49.2869 28.7319 47.3993 28.7319C46.4722 28.7319 45.915 28.2535 45.9331 27.503C45.9512 26.7525 46.4767 26.3413 47.3873 26.3398C50.4567 26.3398 53.5272 26.3398 56.5987 26.3398C57.5787 26.3398 58.0015 26.7704 58.0015 27.7586C58.0015 30.7737 58.0015 33.7887 58.0015 36.8037C58.0015 37.7561 57.5591 38.2898 56.7935 38.2883C56.0279 38.2868 55.5854 37.7396 55.5854 36.8037C55.5854 34.763 55.5854 32.7222 55.5854 30.4737C55.2623 30.7727 55.0523 30.9566 54.8545 31.1524C49.519 36.4349 44.1809 41.72 38.8403 47.0075C37.6579 48.1781 37.2653 48.1736 36.0603 46.9776L27.6372 38.6277L22.8578 41.6223C15.9659 45.9639 9.06994 50.3021 2.16996 54.6367C1.83171 54.849 1.32733 55.0688 1.00267 54.9671C0.792212 54.8837 0.600501 54.7599 0.438531 54.6029C0.276561 54.4459 0.147527 54.2587 0.0588767 54.0521C-0.155552 53.5124 0.209893 53.0609 0.691603 52.7529C2.62146 51.538 4.55031 50.321 6.47816 49.102L26.8383 36.2819C27.7232 35.7258 28.1566 35.7602 28.8951 36.4883C31.5487 39.1086 34.1989 41.7334 36.8455 44.3627C37.0179 44.5526 37.1782 44.7528 37.3257 44.9622Z" fill="#ED6E0F"/>
      <path d="M24.1743 11.98C25.3657 11.98 26.4394 11.98 27.5236 11.98C28.4659 11.98 29.0201 12.451 28.9974 13.2045C28.9763 13.9191 28.4251 14.3706 27.5387 14.3721C25.6768 14.3721 23.8149 14.3721 21.9515 14.3721C20.4173 14.3796 19.333 15.3783 19.3361 16.7642C19.3391 18.1501 20.4384 19.1323 21.9636 19.1563C22.9678 19.1712 23.9931 19.0875 24.9701 19.2594C27.4934 19.7079 29.1031 21.7337 28.9959 24.1722C28.8932 26.5284 27.0736 28.4032 24.5835 28.7171L24.1743 28.7694C24.1743 29.8982 24.1849 31.0345 24.1743 32.1707C24.1637 33.0214 23.5461 33.603 22.8062 33.4938C22.1523 33.3966 21.7718 32.9212 21.7627 32.1483C21.7507 31.0345 21.7627 29.9191 21.7627 28.7246C20.5879 28.7246 19.4886 28.7246 18.3877 28.7246C17.4968 28.7246 16.9562 28.2761 16.9381 27.5555C16.9185 26.7945 17.4621 26.3355 18.4074 26.3325C20.2436 26.3325 22.0814 26.3325 23.9176 26.3325C25.5077 26.3325 26.5964 25.3353 26.5844 23.9135C26.5708 22.5216 25.5016 21.5708 23.9418 21.5498C22.9376 21.5379 21.9092 21.6336 20.9322 21.4556C18.4557 21.0071 16.855 18.9858 16.9366 16.5773C17.0091 14.2106 18.7607 12.3657 21.2931 11.992C21.4169 11.974 21.5438 11.971 21.7627 11.9531C21.7627 10.8169 21.7627 9.67962 21.7627 8.54139C21.7627 7.93589 21.9877 7.47093 22.5963 7.26461C23.3996 6.99251 24.1547 7.54867 24.1743 8.46065C24.1924 9.5954 24.1743 10.7376 24.1743 11.98Z" fill="#ED6E0F"/>
    </svg>
  );
};

export default IncreaseSalesSvg;
