/* eslint-disable max-len */
import React from 'react';

const CuttingEdgeSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="62" viewBox="0 0 55 62" fill="none">
      <g clipPath="url(#clip0_13708_5896)">
        <path d="M36.5092 54.1672C36.6129 55.4969 36.2162 56.7828 34.7609 57.5842C34.5854 57.6819 34.5276 58.0866 34.5077 58.3577C34.3462 60.431 32.9427 61.8026 30.8734 61.8245C28.6805 61.8484 26.4856 61.8405 24.2926 61.8265C22.0838 61.8146 20.6962 60.4888 20.5228 58.2899C20.5029 58.0268 20.4092 57.656 20.2218 57.5364C18.6189 56.5097 18.5611 55.6843 18.5013 53.9938C16.6174 53.1844 15.8 50.4452 16.9244 48.7108C17.7557 47.431 17.4567 45.7942 16.2685 44.8592C10.1064 40.0069 7.29746 33.6135 8.23843 25.8665C9.18736 18.0537 13.3838 12.3561 20.7142 9.27603C29.4679 5.59789 39.7109 9.54516 44.4297 18.261C49.3797 27.4036 46.9814 38.5955 38.7201 44.8971C37.5678 45.7763 37.3565 47.423 38.0682 48.6949C38.8058 50.0126 38.7161 51.4819 38.1719 52.8196C37.9526 53.3578 37.1631 53.6609 36.5092 54.1672ZM35.6241 47.8117C35.9769 44.4864 35.9769 44.4864 37.7413 43.1109C44.4297 37.8957 46.7821 28.9585 43.5445 21.06C40.3748 13.329 32.9766 9.02085 24.813 10.1552C17.3191 11.1958 10.6586 18.6637 10.1363 26.5204C9.66981 33.5697 12.1638 39.2454 17.8135 43.5076C18.8163 44.2652 19.2868 45.2739 19.3267 46.5059C19.3406 46.9266 19.3286 47.3492 19.3286 47.8117C24.811 47.8117 30.1836 47.8117 35.6241 47.8117ZM36.4414 49.9249C30.4388 49.9249 24.5179 49.9249 18.589 49.9249C18.589 50.5768 18.589 51.1629 18.589 51.7969C24.5558 51.7969 30.4747 51.7969 36.4414 51.7969C36.4414 51.1649 36.4414 50.5788 36.4414 49.9249ZM20.6265 53.8981C20.4231 55.5388 20.6783 55.8378 22.2532 55.8398C25.7659 55.8418 29.2805 55.8418 32.7932 55.8398C34.3562 55.8398 34.6253 55.5149 34.3921 53.8981C29.8049 53.8981 25.2137 53.8981 20.6265 53.8981ZM22.5762 57.8872C22.5602 59.2907 23.0606 59.819 24.3963 59.827C26.4816 59.8389 28.5668 59.8389 30.6521 59.827C31.9778 59.819 32.4802 59.2787 32.4543 57.8892C29.1868 57.8872 25.9194 57.8872 22.5762 57.8872Z" fill="#ED6E0F"/>
        <path d="M12.5287 10.8687C12.2117 11.1837 11.7911 11.6003 11.3844 12.007C10.2341 10.8129 9.11769 9.65462 7.9375 8.42858C8.26245 8.15147 8.73493 7.74877 9.22734 7.32812C10.254 8.43057 11.3525 9.60677 12.5287 10.8687Z" fill="#ED6E0F"/>
        <path d="M42.502 10.575C43.5605 9.54236 44.7128 8.41799 45.897 7.26172C46.2977 7.66243 46.7343 8.09902 47.1809 8.54558C46.0126 9.67793 44.8763 10.7784 43.6682 11.9486C43.3353 11.5559 42.9226 11.0694 42.502 10.575Z" fill="#ED6E0F"/>
        <path d="M26.584 0.0117188C27.2419 0.0117188 27.828 0.0117188 28.4639 0.0117188C28.4639 1.63847 28.4639 3.21937 28.4639 4.88201C27.8718 4.88201 27.2638 4.88201 26.584 4.88201C26.584 3.3031 26.584 1.69628 26.584 0.0117188Z" fill="#ED6E0F"/>
        <path d="M0.626953 27.8408C0.626953 27.1909 0.626953 26.6048 0.626953 25.9648C2.27364 25.9648 3.88046 25.9648 5.53113 25.9648C5.53113 26.5948 5.53113 27.1809 5.53113 27.8408C3.9283 27.8408 2.32149 27.8408 0.626953 27.8408Z" fill="#ED6E0F"/>
        <path d="M49.4922 27.8606C49.4922 27.2107 49.4922 26.6246 49.4922 25.9727C51.1169 25.9727 52.7238 25.9727 54.3884 25.9727C54.3884 26.5946 54.3884 27.2027 54.3884 27.8606C52.7676 27.8606 51.1867 27.8606 49.4922 27.8606Z" fill="#ED6E0F"/>
        <path d="M28.5097 35.947C28.5097 34.8824 28.5058 33.8198 28.5117 32.7553C28.5197 31.4754 28.7988 31.2182 30.0408 31.3279C31.7852 31.4814 33.1926 30.4946 33.6053 28.8319C34.0259 27.1334 33.2704 25.3691 31.7433 24.7511C31.1891 24.5258 30.5232 24.5079 29.9092 24.5198C28.8905 24.5418 28.5257 24.3025 28.5177 23.3237C28.4978 20.9633 28.5217 18.6029 28.5058 16.2445C28.5018 15.4132 28.9264 14.9447 29.7039 14.9826C31.0535 15.0524 32.4032 15.1999 33.7409 15.3953C34.5343 15.5109 34.616 16.1807 34.624 16.8546C34.64 18.1245 35.1523 18.88 36.3544 19.209C36.6515 19.2907 37.0821 19.0355 37.4031 18.8501C38.5952 18.1584 38.7726 18.1683 39.6658 19.211C40.9157 20.6683 41.757 22.3369 42.3212 24.173C42.6003 25.078 42.3331 25.5964 41.5557 26.0569C40.1143 26.9101 40.1223 28.9157 41.5736 29.7689C42.367 30.2354 42.5883 30.7717 42.3132 31.6608C41.7271 33.5627 40.8101 35.2592 39.5242 36.7763C38.9142 37.496 38.3301 37.5638 37.5346 37.0674C37.1479 36.8261 36.5099 36.5869 36.173 36.7464C35.3975 37.1132 34.4566 37.3983 34.5822 38.6921C34.7177 40.0976 34.5104 40.3129 33.1268 40.576C32.0603 40.7774 30.9558 40.8133 29.8654 40.8512C28.9423 40.8831 28.4639 40.4185 28.4978 39.4377C28.5376 38.2735 28.5097 37.1092 28.5097 35.947ZM30.5113 22.313C33.8605 23.0924 35.5909 24.8607 35.6786 27.4942C35.7863 30.7378 34.4426 32.3267 30.5811 33.5188C30.5811 35.2931 30.5811 37.0694 30.5811 38.9214C31.2788 38.8317 31.8968 38.7499 32.4949 38.6742C33.1249 35.3011 35.0706 34.1009 38.5075 35.0399C39.1215 33.8856 39.7515 32.6994 40.4951 31.302C38.4198 30.8634 38.5553 29.5038 38.5314 27.9229C38.5075 26.316 38.4716 25.0103 40.4931 24.474C39.7335 23.0885 39.0677 21.8724 38.4058 20.6643C35.6208 22.0578 33.0371 20.445 32.5068 17.1416C31.8928 17.0699 31.2549 16.9941 30.5631 16.9124C30.5392 17.1835 30.5133 17.343 30.5113 17.5025C30.5093 19.0933 30.5113 20.6862 30.5113 22.313Z" fill="#ED6E0F"/>
        <path d="M26.5215 27.9429C26.5215 31.1665 26.5375 34.3902 26.5016 37.6138C26.4956 38.0723 26.3601 38.6345 26.073 38.9654C23.5053 41.9139 18.019 41.4075 16.2387 37.9407C15.5709 36.6409 14.8532 35.5464 13.8125 34.5138C12.2755 32.9907 12.1997 30.8057 13.2105 28.8779C13.358 28.5968 13.3959 28.1164 13.2543 27.8493C11.9525 25.3693 12.6024 22.6979 14.909 21.107C15.1881 20.9136 15.519 20.5368 15.521 20.2418C15.5469 16.9783 18.8004 14.5242 22.4028 15.0007C23.1105 15.0944 23.8202 15.3496 24.4741 15.6506C25.8696 16.2905 26.6531 17.2355 26.5654 18.9719C26.4119 21.9563 26.5215 24.9526 26.5215 27.9429ZM18.5014 29.0454C17.9073 29.0454 17.3531 29.0454 16.7969 29.0454C15.4273 29.0474 14.7356 29.6355 14.588 31.0091C14.4784 32.0377 14.915 32.8711 15.6466 33.5788C16.147 34.0632 16.6374 34.2028 17.1976 33.6904C18.1685 32.8033 19.3168 32.9947 20.493 33.0903C20.493 33.7442 20.493 34.3264 20.493 35.0321C20.0265 35.0321 19.6019 35.0082 19.1832 35.0361C17.7997 35.1298 17.375 35.9192 18.0708 37.0755C19.261 39.0551 22.4885 39.5555 24.191 37.9945C24.3944 37.8071 24.51 37.4224 24.51 37.1273C24.528 30.9831 24.528 24.839 24.51 18.6948C24.51 18.3997 24.4024 18.013 24.199 17.8236C22.5105 16.2447 19.0417 16.8348 17.9193 18.8622C17.3431 19.9029 17.3651 20.5727 18.2761 21.3024C18.8662 21.7748 19.6577 21.9981 20.4691 22.3849C20.4691 22.7597 20.4691 23.3677 20.4691 23.9458C18.7965 24.3924 17.5545 23.8342 16.6733 22.5204C14.9469 23.3278 14.2312 24.7831 14.7336 26.2524C14.917 26.7847 15.1941 27.0777 15.8121 27.0478C16.6952 27.004 17.5804 27.0359 18.5034 27.0359C18.5014 27.7516 18.5014 28.3357 18.5014 29.0454Z" fill="#ED6E0F"/>
        <path d="M21.5879 22.1055C22.2139 22.1055 22.796 22.1055 23.4519 22.1055C23.4519 22.7095 23.4519 23.3156 23.4519 23.9914C22.8558 23.9914 22.2537 23.9914 21.5879 23.9914C21.5879 23.3614 21.5879 22.7594 21.5879 22.1055Z" fill="#ED6E0F"/>
        <path d="M19.5723 28.9933C19.5723 28.3354 19.5723 27.7553 19.5723 27.1094C20.2122 27.1094 20.8143 27.1094 21.4761 27.1094C21.4761 27.7354 21.4761 28.3374 21.4761 28.9933C20.8462 28.9933 20.268 28.9933 19.5723 28.9933Z" fill="#ED6E0F"/>
        <path d="M21.5684 34.9796C21.5684 34.3257 21.5684 33.7456 21.5684 33.0977C22.2043 33.0977 22.8064 33.0977 23.4702 33.0977C23.4702 33.7196 23.4702 34.3217 23.4702 34.9796C22.8442 34.9796 22.2661 34.9796 21.5684 34.9796Z" fill="#ED6E0F"/>
      </g>
      <defs>
        <clipPath id="clip0_13708_5896">
          <rect width="53.8264" height="62" fill="white" transform="translate(0.585938)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default CuttingEdgeSvg;
