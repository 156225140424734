/* eslint-disable max-len */
import React from 'react';

const UiUxConsultingSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="61" height="62" viewBox="0 0 61 62" fill="none">
      <path d="M46.2875 32.476C45.4338 39.5853 40.7774 42.426 38.7663 44.4882C36.7523 46.5564 37.113 46.9682 37.2483 50.0013C37.2708 50.3766 37.2163 50.7525 37.0881 51.1059C36.9599 51.4593 36.7606 51.7827 36.5026 52.0562C36.2447 52.3296 35.9334 52.5474 35.5881 52.696C35.2428 52.8446 34.8707 52.9209 34.4947 52.9202H26.5046C26.1287 52.9204 25.7566 52.8438 25.4114 52.6951C25.0661 52.5464 24.7548 52.3287 24.4967 52.0554C24.2386 51.782 24.039 51.4588 23.9103 51.1056C23.7816 50.7524 23.7263 50.3766 23.748 50.0013C23.748 47.0253 24.0246 46.3339 22.233 44.4882C19.9484 42.2036 14.5736 38.9781 14.5736 30.1072C14.5598 27.9038 15.0027 25.7214 15.8743 23.6976C16.7459 21.6738 18.0273 19.8525 19.6377 18.3485C21.2481 16.8446 23.1526 15.6905 25.2312 14.9591C27.3098 14.2277 29.5173 13.9349 31.7147 14.099C33.912 14.2631 36.0516 14.8807 37.9984 15.9127C39.9453 16.9448 41.6572 18.369 43.0263 20.0955C44.3955 21.822 45.3921 23.8134 45.9533 25.9442C46.5146 28.075 46.6284 30.299 46.2875 32.476Z" stroke="#ED6E0F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M25.8704 52.7534V57.2986C25.8704 58.5401 26.5618 59.5591 27.4125 59.5591H33.584C34.4407 59.5591 35.1291 58.5371 35.1291 57.2986V52.7534M28.155 21.0695C26.3801 21.0703 24.6781 21.776 23.4233 23.0314C22.1684 24.2867 21.4635 25.989 21.4635 27.764M52.5402 30.713H58.0533M46.991 12.6405L50.9019 8.72964M49.9971 46.0318L53.908 49.9397M30.4998 2.07422V6.53221M10.1788 8.72663L14.0626 12.6405M7.17273 49.9397L11.0566 46.0318M8.45933 30.713H2.94922" stroke="#ED6E0F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default UiUxConsultingSvg;
