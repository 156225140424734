/* eslint-disable max-len */
import React from 'react';

const TwitterSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
      <g clipPath="url(#clip0_16016_1814)">
        <path d="M17.4843 0.296875C19.7602 0.296875 22.0138 0.745214 24.1165 1.6163C26.2191 2.48738 28.1296 3.76415 29.7389 5.37369C31.3482 6.98324 32.6248 8.89406 33.4957 10.997C34.3667 13.1 34.8149 15.354 34.8149 17.6302C34.8149 19.9065 34.3667 22.1604 33.4957 24.2634C32.6248 26.3664 31.3482 28.2772 29.7389 29.8867C28.1296 31.4963 26.2191 32.773 24.1165 33.6441C22.0138 34.5152 19.7602 34.9635 17.4843 34.9635C7.91483 34.9635 0.159105 27.1755 0.169933 17.5937C0.176701 8.05383 7.9392 0.300936 17.4843 0.296875ZM16.0861 19.9099C16.254 20.1428 16.4001 20.3377 16.5368 20.5381C17.866 22.4712 19.2669 24.3597 20.4919 26.3591C21.1876 27.4949 21.9726 27.9606 23.3059 27.828C24.6391 27.6953 25.9845 27.7982 27.4558 27.7982L19.3982 16.0666C21.8346 13.2238 24.2709 10.3985 26.7316 7.53805C25.661 7.31874 24.8069 7.40267 24.256 8.36654C24.1145 8.57071 23.9541 8.76109 23.7769 8.93512L18.6199 14.9268C18.4467 14.6804 18.3032 14.4828 18.1651 14.2811C16.9889 12.5713 15.7179 10.9184 14.6717 9.13683C13.8947 7.81151 12.9757 7.25376 11.4367 7.43381C10.201 7.58001 8.93541 7.46359 7.54668 7.46359L15.3119 18.7741L7.63196 27.7088C8.77434 28.0202 9.50254 27.6682 10.2037 26.8045C12.0918 24.4653 14.0869 22.2275 16.0861 19.9099Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_16016_1814">
          <rect width="34.6667" height="34.6667" fill="white" transform="translate(0.166992 0.296875)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default TwitterSvg;
