/* eslint-disable max-len */
import React from 'react';

const LinkdeInSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
      <circle cx="20.7632" cy="20.8941" r="17.5034" fill="white"/>
      <path d="M17.1164 28.6296H14.0755V18.8375H17.1164V28.6296ZM15.5941 17.5017C14.6219 17.5017 13.833 16.6961 13.833 15.7239C13.833 14.7517 14.6214 13.9629 15.5941 13.9629C16.5669 13.9629 17.3552 14.7512 17.3552 15.7239C17.3552 16.6961 16.5663 17.5017 15.5941 17.5017ZM28.4971 28.6296H25.463V23.8629C25.463 22.7267 25.44 21.27 23.8821 21.27C22.3012 21.27 22.0587 22.5041 22.0587 23.7812V28.6296H19.021V18.8375H21.9372V20.1732H21.9796C22.3856 19.4037 23.3772 18.5918 24.8564 18.5918C27.9339 18.5918 28.4997 20.6184 28.4997 23.2506V28.6296H28.4971Z" fill="#242424"/>
    </svg>
  );
};

export default LinkdeInSvg;
