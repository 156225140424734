/* eslint-disable max-len */
import React from 'react';

const MobileAppUiUx = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="39" height="64" viewBox="0 0 39 64" fill="none">
      <g clipPath="url(#clip0_13763_67427)">
        <path d="M0.363322 31.9158C0.363322 23.211 0.361328 14.5062 0.363322 5.79946C0.365316 2.16083 2.53852 -0.000417154 6.20108 -0.00241092C15.0734 -0.00440469 23.9436 -0.00440469 32.8159 -0.00241092C36.4685 -0.000417154 38.6337 2.16482 38.6337 5.8174C38.6357 23.1951 38.6357 40.5728 38.6337 57.9485C38.6337 61.6549 36.4745 63.8002 32.7461 63.8022C23.9077 63.8042 15.0694 63.8042 6.23099 63.8022C2.50862 63.8002 0.363322 61.6529 0.361328 57.9305C0.361328 49.2596 0.363322 40.5887 0.363322 31.9158ZM10.9961 2.19074C8.93653 2.19074 7.01254 2.12095 5.09653 2.21466C3.80058 2.27846 2.70401 3.53055 2.54052 4.88831C2.49666 5.24918 2.50064 5.61802 2.50064 5.98288C2.49865 23.2609 2.49865 40.5409 2.50064 57.8189C2.50064 58.118 2.49666 58.417 2.52058 58.7161C2.65416 60.3948 3.91223 61.629 5.59497 61.633C14.866 61.6489 24.137 61.6489 33.4081 61.633C35.0908 61.631 36.3469 60.3968 36.4824 58.7161C36.5064 58.419 36.5024 58.118 36.5024 57.8189C36.5024 40.5409 36.5044 23.2609 36.5024 5.98288C36.5024 5.61802 36.5104 5.24918 36.4625 4.88831C36.2771 3.52855 35.4796 2.43996 34.1956 2.2984C32.1779 2.0751 30.1183 2.23859 28.0588 2.23859C28.0388 2.6812 28.0408 3.04806 28.0049 3.40893C27.8375 5.12556 26.5634 6.36768 24.8368 6.38363C21.282 6.41553 17.7251 6.41553 14.1702 6.38363C12.4436 6.36768 11.1676 5.12357 11.0061 3.40893C10.9682 3.04806 10.9961 2.6812 10.9961 2.19074ZM25.8178 2.23061C21.5511 2.23061 17.3582 2.23061 13.1693 2.23061C13.0297 3.94924 13.255 4.21042 14.8281 4.21242C17.9444 4.21441 21.0626 4.21441 24.1789 4.21242C25.748 4.21042 25.9813 3.93329 25.8178 2.23061Z" fill="#ED6E0F"/>
        <path d="M33.4599 27.8705C33.0532 28.1656 32.6883 28.5963 32.2338 28.7339C28.1824 29.962 24.1151 31.1403 20.0498 32.3286C15.9845 33.5169 11.9192 34.6972 7.85395 35.8815C7.63064 35.9473 7.41133 36.031 7.18404 36.0689C6.55401 36.1686 5.93594 36.1467 5.74653 35.3891C5.56111 34.6434 5.93195 34.2207 6.66167 34.0094C11.5544 32.5918 16.4431 31.1563 21.3338 29.7248C24.6674 28.7498 28.001 27.7709 31.3346 26.7979C32.0105 26.6005 32.7083 26.3772 33.1888 27.1588C33.2705 27.2904 33.2685 27.4758 33.3064 27.6353C33.3582 27.713 33.4101 27.7908 33.4599 27.8705Z" fill="#ED6E0F"/>
        <path d="M9.93672 44.2984C9.93672 43.2039 9.93473 42.1093 9.93672 41.0147C9.93872 39.8523 10.2856 39.2881 11.0193 39.3619C12.078 39.4655 12.0721 40.275 12.0701 41.0705C12.0641 43.1939 12.0621 45.3173 12.0701 47.4406C12.076 48.9958 12.9513 50.0265 14.2213 49.9966C15.4694 49.9667 16.3068 48.9599 16.3108 47.4685C16.3168 45.2455 16.3228 43.0224 16.3068 40.7994C16.3008 40.0557 16.4583 39.4117 17.3157 39.3758C18.2408 39.3379 18.4481 40.0138 18.4481 40.7894C18.4481 43.1122 18.492 45.4349 18.4322 47.7556C18.3644 50.4054 16.554 52.12 14 52.0622C11.6274 52.0064 9.95467 50.1641 9.93872 47.5842C9.93074 46.4876 9.93672 45.393 9.93672 44.2984Z" fill="#ED6E0F"/>
        <path d="M9.93697 15.6287C9.93697 14.3986 9.95292 13.1704 9.93099 11.9403C9.91903 11.1866 10.234 10.6603 10.9897 10.6563C11.7493 10.6523 12.0683 11.1966 12.0683 11.9343C12.0663 14.1613 12.1321 16.3884 12.0504 18.6114C11.9806 20.4756 13.1031 21.2851 14.2176 21.2492C15.699 21.2013 16.3848 19.9851 16.335 18.6433C16.2532 16.4542 16.329 14.259 16.3051 12.0659C16.2971 11.2983 16.5124 10.6842 17.3378 10.6583C18.1732 10.6304 18.4484 11.2464 18.4464 12.0061C18.4444 14.3647 18.4942 16.7253 18.4284 19.0819C18.3567 21.6659 16.4985 23.4363 14.0501 23.3785C11.6835 23.3247 9.95691 21.4545 9.93697 18.9165C9.93099 17.8219 9.93697 16.7253 9.93697 15.6287Z" fill="#ED6E0F"/>
        <path d="M24.8129 19.1052C24.1569 20.1938 23.5588 21.1887 22.9607 22.1836C22.2569 23.3539 21.7285 23.643 21.0666 23.2204C20.4306 22.8136 20.4565 22.2015 21.1543 21.0392C21.8043 19.9585 22.4124 18.85 23.1162 17.8073C23.513 17.2171 23.4691 16.7805 23.0943 16.2063C22.3486 15.0698 21.6807 13.8815 20.9888 12.7092C20.4545 11.808 20.4924 11.1082 21.1244 10.8151C21.9498 10.4303 22.4144 10.9009 22.8032 11.5728C23.4332 12.6633 24.0971 13.734 24.8129 14.9243C25.5287 13.742 26.2125 12.6514 26.8545 11.5389C27.2533 10.847 27.7756 10.3865 28.5412 10.843C29.3328 11.3176 29.0855 11.9994 28.6788 12.6574C27.9292 13.8716 27.2393 15.1237 26.4617 16.3179C26.1188 16.8443 26.1507 17.2271 26.4797 17.7375C27.2672 18.9657 27.985 20.2397 28.7446 21.4858C29.1414 22.1357 29.253 22.7698 28.5173 23.2084C27.7697 23.653 27.2812 23.2044 26.9024 22.5684C26.2245 21.43 25.5366 20.3015 24.8129 19.1052Z" fill="#ED6E0F"/>
        <path d="M25.9452 41.4604C25.9452 44.3374 25.9452 47.1087 25.9452 50.0057C26.641 50.0057 27.295 50.0037 27.9489 50.0057C28.6288 50.0077 29.0296 50.3586 29.0375 51.0285C29.0455 51.7064 28.6448 52.0612 27.9649 52.0612C25.8734 52.0672 23.782 52.0632 21.6925 52.0652C21.0106 52.0652 20.5959 51.7183 20.5939 51.0524C20.5919 50.3366 21.0286 49.9857 21.7543 49.9997C22.3484 50.0117 22.9406 50.0017 23.6205 50.0017C23.6205 47.1446 23.6205 44.3533 23.6205 41.4604C22.9825 41.4604 22.3584 41.4624 21.7364 41.4604C21.0186 41.4564 20.582 41.0935 20.588 40.3798C20.5939 39.7099 21.0385 39.349 21.6905 39.345C23.782 39.329 25.8734 39.3251 27.9629 39.349C28.6109 39.357 29.0435 39.7278 29.0356 40.4077C29.0296 41.0796 28.6169 41.4345 27.9529 41.4544C27.305 41.4763 26.649 41.4604 25.9452 41.4604Z" fill="#ED6E0F"/>
        <path d="M19.5036 57.4085C21.8243 57.4085 24.1471 57.4185 26.4678 57.4025C27.2374 57.3985 27.8914 57.5381 27.9871 58.4413C28.0628 59.1511 27.4707 59.5857 26.4578 59.5877C21.8144 59.5937 17.1729 59.5937 12.5294 59.5877C11.5185 59.5857 10.9304 59.1431 11.0101 58.4313C11.1138 57.5261 11.7737 57.3966 12.5393 57.4025C14.8621 57.4165 17.1828 57.4085 19.5036 57.4085Z" fill="#ED6E0F"/>
      </g>
      <defs>
        <clipPath id="clip0_13763_67427">
          <rect width="38.2804" height="64" fill="white" transform="translate(0.359375)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default MobileAppUiUx;
