/* eslint-disable max-len */
import React from 'react';

const Svg07 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
    >
      <g clipPath="url(#clip0_12998_20448)">
        <path
          d="M15.3075 2.45193C15.515 3.11506 15.5568 3.70721 15.4238 4.30348C15.1604 5.48503 14.5243 6.37715 13.3847 6.83511C12.7518 7.08928 12.1071 7.02105 11.4819 6.77421C11.3398 6.71833 11.2549 6.73986 11.151 6.84748C9.00434 9.01854 6.8565 11.1887 4.70746 13.3579C4.401 13.668 4.06366 13.8997 3.62054 13.967C2.91226 14.0746 2.23667 13.7577 1.85439 13.1138C1.50751 12.5295 1.53067 11.7312 1.97697 11.2041C2.39331 10.7127 2.86322 10.2662 3.31725 9.80731C5.10791 7.99835 6.901 6.19198 8.69651 4.38821C8.81637 4.26822 8.82908 4.18121 8.76643 4.01863C8.31241 2.83799 8.6393 1.6624 9.65904 0.825693C10.5952 0.0576854 11.6608 -0.164885 12.8322 0.117679C12.8686 0.126889 12.9043 0.138829 12.9389 0.1534C12.9516 0.158438 12.9611 0.172177 12.9952 0.202403L11.5105 1.68575L11.333 1.86298C10.8667 2.33057 10.8667 2.33057 11.0275 2.9676C11.1183 3.32161 11.1973 3.6779 11.2999 4.02733C11.3156 4.07334 11.341 4.1154 11.3743 4.15064C11.4076 4.18588 11.448 4.21349 11.4928 4.23158C11.9923 4.36897 12.503 4.48529 13.0056 4.62085C13.1418 4.65749 13.224 4.63001 13.3235 4.52926C13.9282 3.90963 14.5416 3.29825 15.1495 2.68183C15.1986 2.62916 15.234 2.55863 15.3075 2.45193ZM3.932 12.2524C3.93442 12.1367 3.9027 12.0229 3.84089 11.9254C3.77907 11.8279 3.68994 11.7512 3.5848 11.7049C3.47966 11.6586 3.36326 11.6449 3.25037 11.6655C3.13748 11.686 3.03319 11.7399 2.95074 11.8204C2.8683 11.9009 2.81141 12.0042 2.7873 12.1173C2.76318 12.2305 2.77294 12.3483 2.81532 12.4558C2.85771 12.5633 2.93081 12.6557 3.02535 12.7213C3.11989 12.7868 3.2316 12.8225 3.34631 12.8239C3.4992 12.8263 3.64676 12.7673 3.75658 12.6599C3.86639 12.5526 3.92949 12.4057 3.932 12.2515V12.2524Z"
          fill="#031B59"
        />
        <path
          d="M9.00293 10.7581L11.2149 8.53516C11.4138 8.74765 11.6195 8.97801 11.836 9.19783C12.5448 9.9173 13.2648 10.6262 13.9668 11.353C14.7268 12.1403 14.4889 13.3919 13.5032 13.8572C12.9225 14.1319 12.2587 14.0056 11.7534 13.5032C10.9616 12.7164 10.1802 11.9186 9.39248 11.1272C9.26535 10.9986 9.13006 10.8781 9.00293 10.7581Z"
          fill="#031B59"
        />
        <path
          d="M6.51893 4.93084L5.32576 6.13346C5.24404 6.03133 5.16322 5.9118 5.06424 5.81013C4.64473 5.38056 4.20387 4.97114 3.80297 4.52508C3.52261 4.20571 3.15435 3.97773 2.7451 3.87019C2.4182 3.78135 2.09675 3.66731 1.77893 3.54962C1.68393 3.51339 1.6039 3.44575 1.55192 3.35773C1.20732 2.67994 0.879061 1.99391 0.534912 1.31566C0.473165 1.19338 0.499043 1.12698 0.585307 1.04088C0.893589 0.737251 1.20051 0.431331 1.49971 0.118541C1.59051 0.0232843 1.66634 0.0196195 1.77712 0.0759492C2.45089 0.420339 3.12965 0.755113 3.8007 1.10454C3.88703 1.15921 3.95211 1.24216 3.98503 1.33948C4.14712 1.79744 4.28922 2.26365 4.45086 2.72207C4.49258 2.84264 4.55929 2.95286 4.64654 3.0454C5.26765 3.67785 5.89738 4.30526 6.51893 4.93084Z"
          fill="#031B59"
        />
      </g>
      <defs>
        <clipPath id="clip0_12998_20448">
          <rect
            width="15"
            height="14"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Svg07;
