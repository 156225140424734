/* eslint-disable max-len */
import React from 'react';

const IllustrationSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
      <g clipPath="url(#clip0_16016_1826)">
        <path d="M17.4739 34.9635C7.90111 34.9635 0.156171 27.1914 0.167004 17.595C0.176482 8.06092 7.94984 0.299584 17.4983 0.296875C19.7743 0.298031 22.0277 0.747553 24.13 1.61977C26.2323 2.49199 28.1423 3.76983 29.7508 5.38032C31.3594 6.99082 32.635 8.90243 33.5049 11.006C34.3748 13.1096 34.822 15.364 34.8208 17.6404C34.8197 19.9168 34.3702 22.1707 33.4982 24.2734C32.6261 26.3761 31.3485 28.2864 29.7384 29.8953C28.1282 31.5041 26.2169 32.78 24.1138 33.6501C22.0106 34.5202 19.7567 34.9674 17.4807 34.9663L17.4739 34.9635ZM7.92277 23.5877C10.3437 23.5877 12.7309 23.6648 15.1085 23.5579C16.5031 23.4942 17.4441 22.4013 17.7244 20.9387C18.0981 18.9845 17.5565 17.7873 16.0184 17.1711C16.8795 16.6836 17.1909 16.3382 17.3602 15.6814C17.9384 13.4401 16.6074 11.6823 14.3028 11.6619C12.3639 11.6457 10.425 11.6525 8.4806 11.6525C8.28968 11.6525 8.09743 11.6823 7.91734 11.6971L7.92277 23.5877ZM27.0414 19.4436C27.2553 17.4758 26.4659 15.7722 25.0807 15.0842C22.2725 13.692 19.1773 15.1614 18.5084 18.199C18.0751 20.1681 18.8144 22.2361 20.3607 23.0351C22.2102 23.9912 24.0693 23.8572 25.8079 22.7209C26.7164 22.1278 27.0793 21.1987 27.0955 20.0896H24.7273C24.4254 21.4181 23.6794 22.0167 22.6273 21.8068C21.6402 21.6104 21.0025 20.5663 21.2232 19.4382L27.0414 19.4436ZM20.5083 14.0265H24.4051C25.422 14.0265 25.5032 13.91 25.2053 12.9025H20.5137L20.5083 14.0265Z" fill="white"/>
        <path d="M10.333 21.836V18.3013C11.6681 18.3013 12.9706 18.235 14.2596 18.323C15.1898 18.3867 15.7395 19.1979 15.6894 20.1472C15.6339 21.1751 15.0463 21.8035 14.0362 21.8292C12.8257 21.8536 11.6125 21.836 10.333 21.836Z" fill="white"/>
        <path d="M10.3232 16.4174V13.438C11.4565 13.438 12.579 13.3919 13.6947 13.4556C14.3812 13.4935 14.7481 14.1652 14.6994 15.0523C14.6507 15.9149 14.2539 16.393 13.5092 16.4065C12.4788 16.439 11.443 16.4174 10.3232 16.4174Z" fill="white"/>
        <path d="M21.1631 18.1883C21.1942 17.071 21.8509 16.4277 22.9016 16.4277C23.9523 16.4277 24.5887 17.0534 24.651 18.1883H21.1631Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_16016_1826">
          <rect width="34.6667" height="34.6667" fill="white" transform="translate(0.166992 0.296875)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationSvg;
