/* eslint-disable max-len */
import React from 'react';

const Svg02 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
    >
      <g clipPath="url(#clip0_12998_20393)">
        <path
          d="M0.0101579 3.79154C0.0101579 2.91363 -0.0177643 2.05572 0.0189788 1.2003C0.049843 0.470292 0.596088 0.00111036 1.32703 0.00111036C4.87037 0.00111036 8.41404 0.00111036 11.958 0.00111036C12.5381 0.00111036 13.1176 -0.00138795 13.6972 0.00111036C14.4179 0.00510764 14.9626 0.495774 14.9866 1.22328C15.0151 2.0727 14.9935 2.92213 14.9935 3.79204L0.0101579 3.79154ZM2.50869 1.90232C2.50714 1.77717 2.46928 1.6553 2.39989 1.55207C2.3305 1.44885 2.23269 1.36889 2.1188 1.3223C2.0049 1.27571 1.88003 1.26456 1.75993 1.29027C1.63984 1.31598 1.5299 1.37739 1.44398 1.46675C1.35807 1.55612 1.30003 1.66943 1.27719 1.7924C1.25435 1.91537 1.26773 2.04249 1.31564 2.15771C1.36356 2.27293 1.44386 2.3711 1.54641 2.43983C1.64897 2.50855 1.76919 2.54476 1.8919 2.54389C2.05613 2.53984 2.2123 2.47048 2.32708 2.35062C2.44187 2.23075 2.50616 2.06987 2.50624 1.90232H2.50869ZM5.00281 1.91531C5.00544 1.74702 4.94266 1.58451 4.8282 1.46328C4.71373 1.34204 4.55688 1.27193 4.3919 1.26825C4.26809 1.2654 4.14624 1.30011 4.04181 1.36798C3.93738 1.43586 3.85507 1.53384 3.80531 1.6495C3.75556 1.76516 3.74061 1.89329 3.76236 2.01763C3.7841 2.14196 3.84157 2.25691 3.92747 2.34789C4.01336 2.43887 4.12382 2.50178 4.24482 2.52864C4.36583 2.5555 4.49192 2.5451 4.60712 2.49876C4.72232 2.45241 4.82143 2.37222 4.89188 2.26835C4.96232 2.16447 5.00094 2.0416 5.00281 1.91531Z"
          fill="#031B59"
        />
        <path
          d="M0.00683661 5.10547H1.24924V12.7148H13.735V5.11346H14.9946V5.37379C14.9946 7.98934 14.9946 10.6052 14.9946 13.2215C14.9946 13.7606 14.7609 14.0009 14.2377 14.0009H0.739247C0.258157 14.0009 0.00781738 13.7546 0.00781738 13.2714C0.00585775 10.6142 0.00585775 7.9567 0.00781738 5.29884L0.00683661 5.10547Z"
          fill="#031B59"
        />
        <path
          d="M6.85912 5.11328V11.4375H2.52344V5.11328H6.85912Z"
          fill="#031B59"
        />
        <path
          d="M10.3007 6.35605C9.79511 6.35605 9.28953 6.35605 8.78198 6.35605C8.37732 6.35605 8.12354 6.10622 8.12599 5.71998C8.12599 5.34474 8.37683 5.1014 8.77463 5.0999C9.79397 5.09691 10.8136 5.09691 11.8336 5.0999C12.2319 5.0999 12.5009 5.36572 12.4955 5.73697C12.4906 6.10023 12.2265 6.35355 11.841 6.35605C11.3285 6.35855 10.8146 6.35605 10.3007 6.35605Z"
          fill="#031B59"
        />
        <path
          d="M10.3012 8.90243C9.79561 8.90243 9.28953 8.90543 8.78248 8.90243C8.37194 8.89943 8.12306 8.6526 8.126 8.26536C8.12894 7.89411 8.38222 7.64728 8.77415 7.64678C9.79414 7.64378 10.8138 7.64378 11.8331 7.64678C12.2319 7.64678 12.5004 7.9096 12.496 8.28235C12.4911 8.6451 12.228 8.89943 11.8415 8.90293C11.329 8.90543 10.8151 8.90243 10.3012 8.90243Z"
          fill="#031B59"
        />
        <path
          d="M10.2968 11.454C9.79077 11.454 9.28469 11.457 8.77813 11.454C8.39061 11.454 8.13194 11.2002 8.12704 10.8324C8.12165 10.4472 8.37199 10.1888 8.77861 10.1868C9.79925 10.1825 10.8196 10.1825 11.8396 10.1868C12.2383 10.1868 12.5039 10.4597 12.497 10.8329C12.4902 11.1947 12.2236 11.451 11.8391 11.454C11.3252 11.4575 10.8112 11.454 10.2968 11.454Z"
          fill="#031B59"
        />
      </g>
      <defs>
        <clipPath id="clip0_12998_20393">
          <rect width="15" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Svg02;
