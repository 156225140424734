export const SET_PUNCH_IN_DATA = "SET_PUNCH_IN_DATA";

export const SET_SEARCH_RESULT = "SET_SEARCH_RESULT";

export const TIMER_DATA = "TIMER_DATA";

export const TOTAL_WORKING_DAYS_LEAVES = "TOTAL_WORKING_DAYS_LEAVES";

export const SET_SHOW_TOAST = "SET_SHOW_TOAST";

export const SET_MONTHLY_REVENUE = "SET_MONTHLY_REVENUE";

export const SET_OVERVIEW_REVENUE = "SET_OVERVIEW_REVENUE";

export const SET_MONTHLY_CLIENTS = "SET_MONTHLY_CLIENTS";

export const SET_MONTHLY_EMPLOYEES = "SET_MONTHLY_EMPLOYEES";

export const SET_TOTAL_EMPLOYEES = "SET_TOTAL_EMPLOYEES";

export const SET_MONTHLY_WORKINGHOURS = "SET_MONTHLY_WORKINGHOURS";

export const SET_TOTAL_AVG = "SET_TOTAL_AVG";

export const SET_ALL_LEAVE_TYPE = "SET_ALL_LEAVE_TYPE";

export const PAGE_COUNT = "PAGE_COUNT";

export const ALL_LEAVES = "ALL_LEAVES";

export const SET_SERVICE_AVAILABLE_DATA = "SET_SERVICE_AVAILABLE_DATA";

export const SET_YOUR_AVG = "SET_YOUR_AVG";

export const SET_YEARLY_WORKINGHOURS = "SET_YEARLY_WORKINGHOURS";

export const SET_OVERIVEW_REVIEW = "SET_OVERIVEW_REVIEW";

export const SET_NOTIFICATION = "SET_NOTIFICATION";

export const SET_INTERVIEW_ID = "SET_INTERVIEW_ID";

export const SET_CHATS = "SET_CHATS";

export const SET_ACTIVECHAT = "SET_ACTIVECHAT";

export const SET_USERNAME = "SET_USERNAME";

export const SET_GROUP_CHATS = "SET_GROUP_CHATS";

export const SET_MESSAGES = "SET_MESSAGES";

export const SET_SENDMESSAGES = "SET_SENDMESSAGES";

export const SET_BROADCASTEDMESSAGE = "SET_BROADCASTEDMESSAGE";

export const SET_USERLIST = "SET_USERLIST";

export const SET_DESIGNATION_DATA = "SET_DESIGNATION_DATA";

export const ALL_DESIGNATION_DATA = "ALL_DESIGNATION_DATA";

export const COMPANY_PROFILE = "COMPANY_PROFILE";

export const SET_SHOW_GROUP_LIST = "SET_SHOW_GROUP_LIST";

export const SET_INTERVIEW_DATA = "SET_INTERVIEW_DATA";

export const SET_INDIVIDUAL_INTERVIEW_DATA = "SET_INDIVIDUAL_INTERVIEW_DATA";

export const SET_ALL_FEEDBACK_DATA = "SET_ALL_FEEDBACK_DATA";

export const SET_ATTENDANCE = "SET_ATTENDANCE";

export const UPDATE_LEAVE_STATUS = "UPDATE_LEAVE_STATUS";

export const SET_INVOICE_DATA = "SET_INVOICE_DATA";

export const SET_NOTIFICATION_UPDATE = "SET_NOTIFICATION_UPDATE";

export const SET_SEARCH_RESULT_FOR_CHAT = "SET_SEARCH_RESULT_FOR_CHAT";

export const SET_MY_FEEDBACK_DATA = "SET_MY_FEEDBACK_DATA";

export const SET_JOB_OPENING_DATA = "SET_JOB_OPENING_DATA";

export const INDIVIDUAL_JOB_OEPNING = "INDIVIDUAL_JOB_OEPNING";

export const UPDATE_JOB_OPENING = "UPDATE_JOB_OPENING";

export const SET_EXPENSE_DATA = " SET_EXPENSE_DATA";

export const SET_ASSET_DOWNLOAD = "SET_ASSET_DOWNLOAD";

export const GET_COMPANY_DATA = "GET_COMPANY_DATA";

export const ALL_COMPANY_TAX_DETAILS = "ALL_COMPANY_TAX_DETAILS";

export const SINGLE_COMPANY_DATA = "SINGLE_COMPANY_DATA";

export const SINGLE_REVIEW = "SINGLE_REVIEW";

export const SINGLE_EXPENSE = "SINGLE_EXPENSE";

export const SINGLE_TDS_DATA = "SINGLE_TDS_DATA";

export const SINGLE_PF_DATA = "SINGLE_PF_DATA";

export const SINGLE_ASSET_DATA = "SINGLE_ASSET_DATA";

export const ADD_ASSET_SUCCESS = "ADD_ASSET_SUCCESS";

export const EDIT_ASSET_SUCCESS = "EDIT_ASSET_SUCCESS";

export const SET_FILES = "SET_FILES";

export const SET_ROOMID = "SET_ROOMID";

export const SET_CHAT_USERS_STATUS = "SET_CHAT_USERS_STATUS";

export const STORE_FILE_DATA = "STORE_FILE_DATA";

export const SET_MY_STATUS = "SET_MY_STATUS";

export const SET_STATUS_BY_NAME_AND_DATE = "SET_STATUS_BY_NAME_AND_DATE";

export const SET_PROJECT_LIST = "SET_PROJECT_LIST";

export const SET_UPDATE_CHATS = "SET_UPDATE_CHATS";

export const SET_INVOICE_BYID = "SET_INVOICE_BYID";

export const SEARCH_LEAVE = "SEARCH_LEAVE";

export const SET_SHOW_PROFILE = "SET_SHOW_PROFILE";

export const SET_EMPLOYEE_NUMBER = "SET_EMPLOYEE_NUMBER";

export const SET_CLIENTS_NUMBER = "SET_CLIENTS_NUMBER";

export const BGV_ALL_EMPLOYEE_DATA = "BGV_ALL_EMPLOYEE_DATA";

export const BGV_FILE_UPLOAD = "BGV_FILE_UPLOAD";

export const BGV_EMPLOYEE_DATA_BY_ID = "BGV_EMPLOYEE_DATA_BY_ID";

export const PROFILE_COMPLETION_BY_ID = "PROFILE_COMPLETION_BY_ID";

export const ON_HOLD_BY_EMPLOYEE_ID = "ON_HOLD_BY_EMPLOYEE_ID";

export const REMOVE_DOCUMENT = "REMOVE_DOCUMENT";

export const NOTIFY_USER = "NOTIFY_USER";

export const SET_SIDE_POPUP_NAV_TAB = "SET_SIDE_POPUP_NAV_TAB";

export const IS_LOADING = "IS_LOADING";

export const SET_CONFIRMDIALOGUE_VALUE = "SET_CONFIRMDIALOGUE_VALUE";

export const SET_IDENTITY_OPTION_TAB = "SET_IDENTITY_OPTION_TAB";

export const SET_ADDRESS_OPTION_TAB = "SET_ADDRESS_OPTION_TAB";

export const SET_FILTER_TAB = "SET_FILTER_TAB"

export const PAGINATION_CURRENT_PAGE = "PAGINATION_CURRENT_PAGE"

export const PAGINATION_PER_PAGE = "PAGINATION_PER_PAGE"
